/**
 * 		File:		./src/context/customers/CustomersState.js
 *
 * 		Subject:	Customer's state
 */
import React, { useReducer } from "react";
import axios from "axios";
import CustomersContext from "./customersContext";
import CustomersReducer from "./customersReducer";

import {
	IS_READING_TYPE_CHANGE_COMMENT,

	//	CUSTOMERS
	CLOSE_CUSTOMER_DETAIL_DIALOG,
	DELETE_CUSTOMER_CONFIRM,
	EDIT_CUSTOMER,
	GET_CUSTOMERS,
	SET_CUSTOMER_DETAIL_DIALOG_ACTIVE_TAB,
	SET_ERR_MSG,
	SET_FOREIGNS,

	//	CUSTOMER ADDRESS
	ABC_ID_CHANGE,
	BUSINESS_PARTNER_TYPES_ID_CHANGE,
	FOLLOW_UP_DATE_CHANGE,
	FOLLOW_UP_DONE_CHANGE,
	IS_PORTAL_ACCESS_ADMINISTRATION_CHANGE,
	SET_ACTIVE_ADDRESS_TAB,
	SUPERVISORS_ID_CHANGE,
	SUPERVISORS_PV_ID_CHANGE,

	//	CUSTOMER OBJECT LIST
	CUSTOMER_OBJECT_LIST_DIALOG,
	CUSTOMER_OBJECT_LIST_IS_FINISHED,

	//	CUSTOMER ADDRESS ADDRESS
	ADDRESS_ADDITION_CHANGE,
	CITY_CHANGE,
	COUNTRY_CHANGE,
	DISTRICT_CHANGE,
	HOUSE_NUMBER_CHANGE,
	NAME_1_CHANGE,
	NAME_2_CHANGE,
	NAME_3_CHANGE,
	LEGAL_FORMS_ID_CHANGE,
	POSTAL_CODE_CHANGE,
	READING_TYPES_ID_CHANGE,
	SALUTATIONS_ID_CHANGE,
	STREET_CHANGE,

	//	CUSTOMER ADDRESS CONTACT
	EMAIL_CHANGE,
	FAX_CHANGE,
	PHONE_CHANGE,
	URL_CHANGE,

	//	CUSTOMER ADDRESS POSTBOX
	POSTBOX_CHANGE,
	POSTBOX_CITY_CHANGE,
	POSTBOX_POSTAL_CODE_CHANGE,

	//	CUSTOMER ADDRESS ADMINISTRATION
	ADMINISTRATIONS_1_ID_CHANGE,
	ADMINISTRATIONS_2_ID_CHANGE,
	ADMINISTRATIONS_3_ID_CHANGE,
	COMMUNITY_CODE_CHANGE,
	GET_ADMINISTRATIONS_1,
	GET_ADMINISTRATIONS_2,
	GET_ADMINISTRATIONS_3,
	OWNER_ID_CHANGE,

	//	CUSTOMER CONTACT PERSONS
	CLOSE_CONTACT_PERSON_DIALOG,
	CLOSE_DELETE,
	CONTACT_PERSON_DIVISION_CHANGE,
	CONTACT_PERSON_EMAIL_CHANGE,
	CONTACT_PERSON_FAX_CHANGE,
	CONTACT_PERSON_FIRSTNAME_CHANGE,
	CONTACT_PERSON_FUNCTION_CHANGE,
	CONTACT_PERSON_IS_INFO_READING_CHANGE,
	CONTACT_PERSON_IS_PORTAL_ACCESS_CHANGE,
	CONTACT_PERSON_LASTNAME_CHANGE,
	CONTACT_PERSON_MOBILE_CHANGE,
	CONTACT_PERSON_PHONE_CHANGE,
	CONTACT_PERSON_PORTAL_ACCESS_VALID_TO_CHANGE,
	CONTACT_PERSON_SALUTATIONS_ID_CHANGE,
	CONTACT_PERSON_TITLE_CHANGE,
	DELETE_CONTACT_PERSON,
	DELETE_CONTACT_PERSON_CONFIRM,
	EDIT_CONTACT_PERSON,
	GET_CONTACT_PERSONS,
	SET_ACTIVE_CONTACT_PERSON,
	SET_CONTACT_PERSON_ERROR,

	//	CUSTOMER BANK
	ACCOUNT_NO_CHANGE,
	ACCOUNT_OWNER_CHANGE,
	BANK_CODE_CHANGE,
	IBAN_CHANGE,

	//	CUSTOMER PRODUCT
	SET_ACTIVE_PRODUCT_TAB,

	//	CUSTOMER PRODUCT ACTIVE
	ACTIVE_ENERGY_CAMPAIGNS_ID_CHANGE,
	ACTIVE_GAS_CAMPAIGNS_ID_CHANGE,
	IS_CUSTOMER_ENERGY_TRANSITION_CHANGE,
	CUSTOMER_ENERGY_TRANSITION_DATE_CHANGE,
	IS_CUSTOMER_ENERGY_TRANSITION_TEN_PERCENT_CHANGE,
	CUSTOMER_ENERGY_TRANSITION_TEN_PERCENT_DATE_CHANGE,
	IS_CUSTOMER_GAS_TRANSITION_CHANGE,
	CUSTOMER_GAS_TRANSITION_DATE_CHANGE,
	IS_CUSTOMER_GAS_TRANSITION_TEN_PERCENT_CHANGE,
	CUSTOMER_GAS_TRANSITION_TEN_PERCENT_DATE_CHANGE,
	OFFERED_ENERGY_CAMPAIGNS_ID_CHANGE,
	OFFERED_GAS_CAMPAIGNS_ID_CHANGE,

	//	CUSTOMER PRODUCTS HISTORY
	GET_PRODUCT_HISTORIES,

	//	CUSTOMERS CONTRACTS
	CLOSE_CONTRACTS_DIALOG,
	CLOSE_DELETE_CONTRACT,
	DELETE_CONTRACT_CONFIRM,
	EDIT_CUSTOMER_CONTRACT,
	GET_CONTRACTS,
	SET_ACTIVE_CONTRACT_TAB,
	SET_CONTRACT_ERR_MSG,
	UPDATE_CONTRACT_EXTERNALS,

	//	CUSTOMER CONTRACTS CONTRACT
	CUSTOMER_CONTRACT_DIVISIONS_ID_CHANGE,
	CUSTOMER_CONTRACT_GROSS_BILL_AMOUNT_CHANGE,
	CUSTOMER_CONTRACT_IS_BIO10_CHANGE,
	CUSTOMER_CONTRACT_BIO10_START_DATE_CHANGE,
	CUSTOMER_CONTRACT_IS_BIO5_CHANGE,
	CUSTOMER_CONTRACT_BIO5_START_DATE_CHANGE,
	CUSTOMER_CONTRACT_IS_CLIMATE_PLUS_CHANGE,
	CUSTOMER_CONTRACT_PERIOD_CONSUMPTION_CHANGE,
	CUSTOMER_CONTRACT_POTS_ID_CHANGE,
	CUSTOMER_CONTRACT_PRODUCTS_ID_CHANGE,
	CUSTOMER_CONTRACT_PROFILE_TYPE_CHANGE,
	CUSTOMER_CONTRACT_VALID_FROM_CHANGE,
	CUSTOMER_CONTRACT_VALID_TO_CHANGE,
	IS_CUSTOMER_CONTRACT_TRANSITION_CHANGE,
	CUSTOMER_CONTRACT_TRANSITION_DATE_CHANGE,
	IS_CUSTOMER_CONTRACT_TRANSITION_TEN_PERCENT_CHANGE,
	CUSTOMER_CONTRACT_TRANSITION_TEN_PERCENT_DATE_CHANGE,

	//	CUSTOMER CONTRACT COUNTER
	CUSTOMER_COUNTER_BHKW_CHANGE,
	CUSTOMER_COUNTER_CONTRACT_ACCOUNT_CHANGE,
	CUSTOMER_COUNTER_CONTRACT_ACCOUNT_DESCRIPTION_CHANGE,
	CUSTOMER_COUNTER_INTENDED_USE_CHANGE,
	CUSTOMER_COUNTER_MEDIUM_VOLTAGE_CHANGE,
	CUSTOMER_COUNTER_NUMBER_CURRENT_CHANGE,
	CUSTOMER_COUNTER_NUMBER_FORMER_CHANGE,
	SET_ACTIVE_CONTRACT_COUNTER_TAB,

	//	CUSTOMER CONTRACT COUNTER CHANGE
	CUSTOMER_COUNTER_CHANGE_REASONS_ID_CHANGE,
	CUSTOMER_COUNTER_CHANGE_SUPPLY_START_CHANGE,
	CUSTOMER_COUNTER_FORMER_SUPPLIER_ID_CHANGE,
	CUSTOMER_COUNTER_INSTALLMENT_CYCLE_CHANGE,
	CUSTOMER_COUNTER_LOAD_PROFILES_ID_CHANGE,
	CUSTOMER_COUNTER_NETWORKS_ID_CHANGE,

	//	CUSTOMER CONTRACT COUNTER LOCATION
	CUSTOMER_COUNTER_LOCATION_ADDITIONAL_CHANGE,
	CUSTOMER_COUNTER_LOCATION_CITY_CHANGE,
	CUSTOMER_COUNTER_LOCATION_COUNTRY_CHANGE,
	CUSTOMER_COUNTER_LOCATION_DISTRICT_CHANGE,
	CUSTOMER_COUNTER_LOCATION_HOUSE_NUMBER_CHANGE,
	CUSTOMER_COUNTER_LOCATION_MARKET_LOCATION_CHANGE,
	CUSTOMER_COUNTER_LOCATION_MEASURE_LOCATION_CHANGE,
	CUSTOMER_COUNTER_LOCATION_POSTAL_CODE_CHANGE,
	CUSTOMER_COUNTER_LOCATION_STREET_CHANGE,

	//	CUSTOMER CONTRACT COUNTER BANK
	CUSTOMER_COUNTER_ACCOUNT_NO_CHANGE,
	CUSTOMER_COUNTER_ACCOUNT_OWNER_CHANGE,
	CUSTOMER_COUNTER_BANK_CODE_CHANGE,
	CUSTOMER_COUNTER_IBAN_CHANGE,

	//	CUSTOMER CONTRACT COUNTER END
	CUSTOMER_COUNTER_CONTRACT_END,
	CUSTOMER_COUNTER_CONTRACT_TERMINATION_REASONS_ID_CHANGE,
	CUSTOMER_COUNTER_VALUE_CHANGE,
	CUSTOMER_COUNTER_VALUE_NT_CHANGE,
	IS_CUSTOMER_COUNTER_LEAVE_DONE_CHANGE,

	//	CUSTOMER CONTRACT BILLING
	CUSTOMER_CONTRACT_BILLING_ADDITIONAL_CHANGE,
	CUSTOMER_CONTRACT_BILLING_CITY_CHANGE,
	CUSTOMER_CONTRACT_BILLING_COUNTRY_CHANGE,
	CUSTOMER_CONTRACT_BILLING_DISTRICT_CHANGE,
	CUSTOMER_CONTRACT_BILLING_HOUSE_NUMBER_CHANGE,
	CUSTOMER_CONTRACT_BILLING_NAME1_CHANGE,
	CUSTOMER_CONTRACT_BILLING_NAME2_CHANGE,
	CUSTOMER_CONTRACT_BILLING_NAME3_CHANGE,
	CUSTOMER_CONTRACT_BILLING_POSTAL_CODE_CHANGE,
	CUSTOMER_CONTRACT_BILLING_POSTBOX_CITY_CHANGE,
	CUSTOMER_CONTRACT_BILLING_POSTBOX_NO_CHANGE,
	CUSTOMER_CONTRACT_BILLING_POSTBOX_POSTAL_CODE_CHANGE,
	CUSTOMER_CONTRACT_BILLING_STREET_CHANGE,
	CUSTOMER_CONTRACT_SALUTATIONS_ID_CHANGE,
	CUSTOMER_CONTRACT_CUSTOMER_TYPES_ID_CHANGE,

	//	CUSTOMER CONTACT HISTORY
	CHANGE_CONTACT_HISTORY_DETAIL_OBJECT_ID,
	CHANGE_CONTACT_HISTORY_DETAIL_REMARKS,
	CHANGE_CONTACT_HISTORY_DETAIL_TOPIC,
	CLOSE_CONTACT_HISTORY_DIALOG,
	CLOSE_DELETE_CUSTOMER_CONTACT_HISTORY_CONFIRM,
	CONTACT_HISTORY_DETAIL_DOCUMENT_CHANGE,
	CONTACT_HISTORY_DETAIL_DOCUMENT_DELETE,
	DELETE_LEAD_CONTACT_HISTORY_CONFIRM,
	EDIT_CONTACT_HISTORY,
	GET_CONTACT_HISTORIES,
	SET_CONTACT_HISTORY_ERROR,

	//	CUSTOMER DELETE
	CLOSE_DELETE_CUSTOMER_CONFIRM,

	//	COUNTER DOUBLINGS
	CUSTOMER_COUNTER_DOUBLINGS_LIST,
	CUSTOMER_DOUBLE_COUNTER_DIALOG_CLOSE,

	//	remember billing address
	CUSTOMER_CONTRACT_BILLING_ADDRESS_SAVED,
	CUSTOMER_CONTRACT_PASTE_BILLING_ADDRESS,
	CLOSE_CUSTOMER_CONTRACT_BILLING_ADDRESS_SAVED_DIALOG,
	CLOSE_CUSTOMER_CONTRACT_BILLING_ADDRESS_PASTE_DIALOG,
	CUSTOMER_CONTRACT_DELETE_BILLING_ADDRESS,
	CUSTOMER_CONTRACT_TAKEOVER_BILLING_ADDRESS,

	//	CUSTOMER COPY DIALOG
	CUSTOMER_COPY_DIALOG,

	//	CUSTOMER ACTIVE CAMPAIGN CHANGE DIALOG
	CUSTOMER_ACTIVE_CAMPAIGN_CHANGE_DIALOG,
} from "../types";

const CustomersState = (props) => {
	const initialState = {

		isReadingTypeChangeComment: false,

		//	CUSTOMERS
		activeCustomerId: -1,
		customerDetailDialogActiveTab: 1,
		customerDetailDialogError: "",
		customerDetailDialogState: false,
		customerDetailDialogTitle: "",
		customers: [],

		//	CUSTOMERS ADDRESS
		abc: [],
		abcId: -1,
		activeAddressTab: 1,
		businessPartnerTypes: [],
		businessPartnerTypesId: -1,
		customerCopies: [],
		followUpDate: "",
		followUpDone: false,
		gpNumber: "",
		isPortalAccessAdministration: false,
		readingTypes: [],
		readingTypesId: -1,
		supervisors: [],
		supervisorsId: -1,
		supervisorsPv: [],
		supervisorsPvId: -1,

		//	CUSTOMER OBJECT LIST
		customerObjectListDialogState: false,
		customerObjectListIsFinished: false,
		customerObjectListIsEmpty: false,
		customerObjectListDivision: '',

		//	CUSTOMERS ADDRESS ADDRESS
		addressAdditional: "",
		city: "",
		country: "DE",
		district: "",
		houseNumber: "",
		name1: "",
		name2: "",
		name3: "",
		legalForms: [],
		legalFormsId: -1,
		postalCode: "",
		salutations: [],
		salutationsId: -1,
		street: "",

		//	CUSTOMER ADDRESS CONTACT
		fax: "",
		email: "",
		phone: "",
		url: "",

		//	CUSTOMER ADDRESS POSTBOX
		postbox: "",
		postboxCity: "",
		postboxPostalCode: "",

		//	CUSTOMER ADDRESS ADMINISTRATION
		administrations1: [],
		administrations1Id: -1,
		administrations2: [],
		administrations2Id: -1,
		administrations3: [],
		administrations3Id: -1,
		communityCode: "",
		ownerId: -1,
		yesNos: [],

		//	CUSTOMER CONTACT PERSONS
		activeContactPerson: 0,
		contactPersonDetailsDialogError: "",
		contactPersonDetailsDialogState: false,
		contactPersonDetailsDialogTitle: "",
		contactPersonSalutationsId: -1,
		contactPersonTitle: "",
		contactPersonFirstname: "",
		contactPersonLastname: "",
		contactPersonFunction: "",
		contactPersonDivision: "",
		contactPersonEmail: "",
		contactPersonIsInfoReading: false,
		contactPersonPhone: "",
		contactPersonFax: "",
		contactPersonMobile: "",
		contactPersonIsPortalAccess: false,
		contactPersonPortalAccessValidTo: "",
		contactPersons: [],
		deleteModalEntityName: "",
		deleteModalState: false,
		deleteModalTitle: "",

		//	CUSTOMER BANK ACCOUNT
		accountNo: "",
		accountOwner: "",
		bankCode: "",
		iban: "",

		//	CUSTOMER PRODUCTS
		activeProductTab: 1,

		//	CUSTOMER PRODUCTS ACTIVE
		activeEnergyCampaignsId: 0,
		activeGasCampaignsId: 0,
		energyCampaigns: [],
		gasCampaigns: [],
		isTransition: 0,
		bIsCustomerEnergyTransitionChange: false,
		isCustomerEnergyTransitionOpen: 0,
		isCustomerEnergyTransitionInitialized: 0,
		isCustomerEnergyInitialTransition: 0,
		isCustomerEnergyTransition: 0,
		customerEnergyTransitionDate: "",
		bIsCustomerEnergyTransitionTenPercentChange: false,
		isCustomerEnergyTransitionTenPercentOpen: 0,
		isCustomerEnergyTransitionTenPercent: 0,
		customerEnergyTransitionTenPercentDate: '',
		bIsCustomerGasTransitionChange: false,
		isCustomerGasTransitionOpen: 0,
		isCustomerGasTransitionInitialized: 0,
		isCustomerGasInitialTransition: 0,
		isCustomerGasTransition: 0,
		customerGasTransitionDate: "",
		bIsCustomerGasTransitionTenPercentChange: false,
		isCustomerGasTransitionTenPercentOpen: 0,
		isCustomerGasTransitionTenPercent: 0,
		customerGasTransitionTenPercentDate: '',
		offeredEnergyCampaignsId: 0,
		offeredGasCampaignsId: 0,

		//	CUSTOMER PRODUCTS HISTORY
		productHistories: [],

		//	CUSTOMER CONTRACTS
		activeCustomerContract: -1,
		activeContractTab: 1,
		contractDialogTitle: "",
		contracts: [],
		customerContractsDialogError: "",
		customerContractsDialogState: false,
		customerContractLastImportDate: "",
		deleteContractName: "",
		deleteContractState: false,
		isContractEdit: false,

		//CUSTOMER CONTRACT CONTRACT
		customerContractBillTurnStart: "",
		customerContractBillTurnEnd: "",
		customerContractGrossBillAmount: "",
		customerContractIsBio10: false,
		customerContractBio10StartDate: "",
		customerContractIsBio5: false,
		customerContractBio5StartDate: "",
		customerContractIsClimatePlus: false,
		customerContractDivisionsId: false,
		customerContractPeriodConsumption: "",
		customerContractPotsId: 0,
		customerContractProducts: [],
		customerContractProductsId: -1,
		customerContractProfileType: "",
		customerContractValidFrom: "",
		customerContractValidTo: "",
		isCustomerContractTransitionOpen: 0,
		isCustomerContractTransitionInitialized: 0,
		isCustomerContractInitialTransition: 0,
		isCustomerContractTransition: 0,
		customerContractTransitionDate: '',
		isCustomerContractTransitionTenPercentOpen: 0,
		isCustomerContractTransitionTenPercent: 0,
		customerContractTransitionTenPercentDate: '',
		divisions: [],
		isOptionen: 0,
		isPots: 0,
		isValidTo: 0,
		pots: [],

		//	CUSTOMER CONTRACT COUNTER
		activeContractCounterTab: 1,
		customerCounterBhkw: false,
		customerCounterContractAccount: "",
		customerCounterContractAccountDescription: "",
		customerCounterIntendedUse: "*",
		customerCounterLoadProfileIms: "",
		customerCounterMediumVoltage: false,
		customerCounterNumberCurrent: "",
		customerCounterNumberDoubling: 0,
		customerCounterNumberFormer: "",

		//	CUSTOMER CONTRACT COUNTER CHANGE
		changeReasons: [],
		customerCounterChangeReasonsId: -1,
		customerCounterChangeSupplyStart: "",
		customerCounterFormerSupplierId: -1,
		customerCounterInstallmentCycle: "",
		customerCounterIsNetworkConfirmed: false,
		customerCounterIsNetworkRequest: false,
		customerCounterLoadProfilesId: -1,
		customerCounterNetworksId: -1,
		loadProfiles: [],
		networks: [],
		suppliers: [],

		//	CUSTOMER CONTRACT COUNTER LOCATION
		customerCounterLocationAdditional: "",
		customerCounterLocationCity: "",
		customerCounterLocationCountry: "DE",
		customerCounterLocationDistrict: "",
		customerCounterLocationHouseNumber: "",
		customerCounterLocationMarketLocation: "",
		customerCounterLocationMeasureLocation: "",
		customerCounterLocationPostalCode: "",
		customerCounterLocationStreet: "",

		//	CUSTOMER CONTRACT COUNTER BANK
		customerCounterAccountNo: "",
		customerCounterAccountOwner: "",
		customerCounterBankCode: "",
		customerCounterIban: "",

		//	CUSTOMER CONTRACT COUNTER END
		customerCounterContractEnd: "",
		customerCounterContractTerminationReasonsId: -1,
		terminationReasons: [],
		customerCounterValue: "",
		customerCounterValueNt: "",
		isCustomerCounterLeaveDone: false,

		//	CUSTOMER CONTRACT BILLING
		customerContractBillingAdditional: "",
		customerContractBillingCity: "",
		customerContractBillingCountry: "DE",
		customerContractCustomerTypesId: -1,
		customerContractBillingDistrict: "",
		customerContractBillingHouseNumber: "",
		customerContractBillingMail1: "",
		customerContractBillingMail2: "",
		customerContractBillingMail3: "",
		customerContractBillingName1: "",
		customerContractBillingName2: "",
		customerContractBillingName3: "",
		customerContractBillingPostalCode: "",
		customerContractBillingPostboxCity: "",
		customerContractBillingPostboxNo: "",
		customerContractBillingPostboxPostalCode: "",
		customerContractBillingStreet: "",
		customerContractSalutationsId: "",
		customerTypes: [],

		//	CUSTOMER CONTRACT HISTORY
		activeContactHistory: -1,
		contactHistories: [],
		contactHistoryDetailDocument: "",
		contactHistoryDetailObjectId: -1,
		contactHistoryDetailRemarks: "",
		contactHistoryDialogState: false,
		contactHistoryDialogTitle: "",
		contactHistoryDetailTopic: "",
		contactHistoryError: "",
		contactHistoryIsUser: false,
		customerObjects: [],
		deleteCustomerContactHistoryConfirmName: "",
		deleteCustomerContactHistoryConfirmState: false,

		//	DELETE CUSTOMER
		deleteCustomerConfirmName: "",
		deleteCustomerConfirmState: false,

		//	COUNTER DOUBLINGS
		customerCounterDoublingsList: [],
		customerDoubleCounterDialogState: false,

		//	remember billing address
		customerContractBillingAddressSavedDialogState: false,
		customerContractBillingAddressPasteDialogState: false,
		customerContractBillingAddresses: [],

		//	Customer Copy Dialog
		customerCopyDialogId: -1,
		customerCopyDialogState: false,

		//	Customer Active Campaign Change Dialog
		customerActiveCampaignChangeContracts: [],
		customerActiveCampaignChangeDialogState: false,
		customerActiveCampaignChangeFileName: '',
		customerActiveCampaignChangeLoading: true,
	};
	const [state, dispatch] = useReducer(CustomersReducer, initialState);

	//------------------------------------
	//	CUSTOMERS
	//------------------------------------

	//	CANCEL THE CUSTOMER DETAIL DIALOG
	const closeCustomerDetailDialog = () => {

		if (state.isCustomerEnergyTransition === 1 && state.customerEnergyTransitionDate === '') {
			return setErrMsg(
				"Bitte geben Sie das Datum für den Kundenübergang (Strom) an",
				4,
				state.activeAddressTab
			);
		}

		if (state.isCustomerEnergyTransitionTenPercent === 1 && state.customerEnergyTransitionTenPercentDate === '') {
			return setErrMsg(
				"Bitte geben Sie das Datum für die 10%-Regel des Kundenübergangs (Strom) an",
				4,
				state.activeAddressTab
			);
		}

		if (state.isCustomerGasTransition === 1 && state.customerGasTransitionDate === '') {
			return setErrMsg(
				"Bitte geben Sie das Datum für den Kundenübergang (Erdgas) an",
				4,
				state.activeAddressTab
			);
		}

		if (state.isCustomerGasTransitionTenPercent === 1 && state.customerGasTransitionTenPercentDate === '') {
			return setErrMsg(
				"Bitte geben Sie das Datum für die 10%-Regel des Kundenübergangs (Erdgas) an",
				4,
				state.activeAddressTab
			);
		}

		setErrMsg("", 1, 1);

		//	Delete the temp values
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", state.activeCustomerId);

		const isCEIT = (state.isCustomerEnergyTransitionInitialized > 0) ? 2 : 0;
		params.append("is_customer_energy_transition_initialized", isCEIT);

		const isCET = (state.isCustomerEnergyTransition === 1 || state.isCustomerEnergyTransition === true) ? 1 : 0;
		params.append("is_customer_energy_transition", isCET);

		const isCETTP = (state.isCustomerEnergyTransitionTenPercent === 1 || state.isCustomerEnergyTransitionTenPercent === true) ? 1 : 0;
		params.append("is_customer_energy_transition_ten_percent", isCETTP);

		let newCustomerEnergyTransitionDate =
			state.customerEnergyTransitionDate !== "" && state.customerEnergyTransitionDate !== null
				? new Date(state.customerEnergyTransitionDate)
				: "";

		if (newCustomerEnergyTransitionDate !== "") {
			const newETDay = newCustomerEnergyTransitionDate.getDate();
			const newETMonth = newCustomerEnergyTransitionDate.getMonth() + 1;
			const newETYear = newCustomerEnergyTransitionDate.getFullYear();
			newCustomerEnergyTransitionDate = newETYear + '-' + newETMonth + '-' + newETDay;
		}
		params.append("customer_energy_transition_date", newCustomerEnergyTransitionDate);

		let newCustomerEnergyTransitionTenPercentDate =
			state.customerEnergyTransitionTenPercentDate !== "" && state.customerEnergyTransitionTenPercentDate !== null ? new Date(state.customerEnergyTransitionTenPercentDate) : "";

		if (newCustomerEnergyTransitionTenPercentDate !== "") {
			const newETTPDay = newCustomerEnergyTransitionTenPercentDate.getDate();
			const newETTPMonth = newCustomerEnergyTransitionTenPercentDate.getMonth() + 1;
			const newETTPYear = newCustomerEnergyTransitionTenPercentDate.getFullYear();
			newCustomerEnergyTransitionTenPercentDate = newETTPYear + '-' + newETTPMonth + '-' + newETTPDay;
		}
		params.append("customer_energy_transition_ten_percent_date", newCustomerEnergyTransitionTenPercentDate);

		const isCGIT = (state.isCustomerGasTransitionInitialized > 0) ? 2 : 0;
		params.append("is_customer_gas_transition_initialized", isCGIT);

		const isCGT = (state.isCustomerGasTransition === 1 || state.isCustomerGasTransition === true) ? 1 : 0;
		params.append("is_customer_gas_transition", isCGT);

		const isCGTTP = (state.isCustomerGasTransitionTenPercent === 1 || state.isCustomerGasTransitionTenPercent === true) ? 1 : 0;
		params.append("is_customer_gas_transition_ten_percent", isCGTTP);

		let newCustomerGasTransitionDate =
			state.customerGasTransitionDate !== "" && state.customerGasTransitionDate !== null
				? new Date(state.customerGasTransitionDate)
				: "";

		if (newCustomerGasTransitionDate !== "") {
			const newGTDay = newCustomerGasTransitionDate.getDate();
			const newGTMonth = newCustomerGasTransitionDate.getMonth() + 1;
			const newGTYear = newCustomerGasTransitionDate.getFullYear();
			newCustomerGasTransitionDate = newGTYear + '-' + newGTMonth + '-' + newGTDay;
		}
		params.append("customer_gas_transition_date", newCustomerGasTransitionDate);

		let newCustomerGasTransitionTenPercentDate = state.customerGasTransitionTenPercentDate !== "" && state.customerGasTransitionTenPercentDate !== null ? new Date(state.customerGasTransitionTenPercentDate) : "";

		if (newCustomerGasTransitionTenPercentDate !== "") {
			const newGTTPDay = newCustomerGasTransitionTenPercentDate.getDate();
			const newGTTPMonth = newCustomerGasTransitionTenPercentDate.getMonth() + 1;
			const newGTTPYear = newCustomerGasTransitionTenPercentDate.getFullYear();
			newCustomerGasTransitionTenPercentDate = newGTTPYear + '-' + newGTTPMonth + '-' + newGTTPDay;
		}
		params.append("customer_gas_transition_ten_percent_date", newCustomerGasTransitionTenPercentDate);

		let url = process.env.REACT_APP_API_URI + "/customers/clear_temps.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					dispatch({
						type: CLOSE_CUSTOMER_DETAIL_DIALOG,
						payload: {
							dialogTitle: "",
						},
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	CONFIRM DIALOG FOR DELETING A CUSTOMER
	const deleteCustomerConfirm = (id) => {
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", id);

		let url = process.env.REACT_APP_API_URI + "/customers/get_customer.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					const entityName =
						"Möchten Sie den Kunden mit der Id " +
						response.data.id +
						' namens "' +
						response.data.customer.name1 +
						'" endgültig löschen?';

					dispatch({
						type: DELETE_CUSTOMER_CONFIRM,
						payload: {
							entityName: entityName,
							id: id,
						},
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	EDIT A CUSTOMER
	const editCustomer = (id) => {
		const customer = { id: id };
		let dialogTitle = "Neuen Kunden anlegen";

		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", id);

		let url = process.env.REACT_APP_API_URI + "/customers/get_customer.php";
		let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" }, };

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					//   console.log(response.data);

					let followUpDate = "";
					if (
						response.data.customer.followUpDate !== "" &&
						response.data.customer.followUpDate !== null
					) {
						followUpDate = new Date(response.data.customer.followUpDate);
					}

					let customerEnergyTransitionDate = "";
					if (
						response.data.customer.customerEnergyTransitionDate !== "" &&
						response.data.customer.customerEnergyTransitionDate !== null
					) {
						customerEnergyTransitionDate = new Date(response.data.customer.customerEnergyTransitionDate);
					}

					let customerEnergyTransitionTenPercentDate = "";
					if (
						response.data.customer.customerEnergyTransitionTenPercentDate !== "" && response.data.customer.customerEnergyTransitionTenPercentDate !== null
					) {
						customerEnergyTransitionTenPercentDate = new Date(response.data.customer.customerEnergyTransitionTenPercentDate);
					}

					let customerGasTransitionDate = "";
					if (
						response.data.customer.customerGasTransitionDate !== "" &&
						response.data.customer.customerGasTransitionDate !== null
					) {
						customerGasTransitionDate = new Date(response.data.customer.customerGasTransitionDate);
					}

					let customerGasTransitionTenPercentDate = "";
					if (
						response.data.customer.customerGasTransitionTenPercentDate !== "" && response.data.customer.customerGasTransitionTenPercentDate !== null
					) {
						customerGasTransitionTenPercentDate = new Date(response.data.customer.customerGasTransitionTenPercentDate);
					}

					//  Get the data for the selected customer
					dialogTitle = "Kunde bearbeiten";
					customer.gpNumber = response.data.customer.gpNumber;
					customer.businessPartnerTypesId =
						response.data.customer.businessPartnerTypesId;
					customer.followUpDate = followUpDate;
					customer.followUpDone = response.data.customer.followUpDone;
					customer.abcId = response.data.customer.abcId;
					customer.isPortalAccessAdministration =
						response.data.customer.isPortalAccessAdministration;
					customer.supervisorsId = response.data.customer.supervisorsId;
					customer.supervisorsPvId = response.data.customer.supervisorsPvId;
					customer.readingTypesId = response.data.customer.readingTypesId;
					customer.salutationsId = response.data.customer.salutationsId;
					customer.name1 = response.data.customer.name1;
					customer.name2 = response.data.customer.name2;
					customer.name3 = response.data.customer.name3;
					customer.legalFormsId = response.data.customer.legalFormsId;
					customer.street = response.data.customer.street;
					customer.houseNumber = response.data.customer.houseNumber;
					customer.addressAddition = response.data.customer.addressAddition;
					customer.postalCode = response.data.customer.postalCode;
					customer.city = response.data.customer.city;
					customer.district = response.data.customer.district;
					customer.country = response.data.customer.country;
					customer.phone = response.data.customer.phone;
					customer.fax = response.data.customer.fax;
					customer.email = response.data.customer.email;
					customer.url = response.data.customer.url;
					customer.postbox = response.data.customer.postbox;
					customer.postboxPostalCode = response.data.customer.postboxPostalCode;
					customer.postboxCity = response.data.customer.postboxCity;
					customer.ownerId = response.data.customer.ownerId;
					customer.administrations1Id =
						response.data.customer.administrations1Id;
					customer.administrations2Id =
						response.data.customer.administrations2Id;
					customer.administrations3Id =
						response.data.customer.administrations3Id;
					customer.communityCode = response.data.customer.communityCode;
					customer.bankCode = response.data.customer.blz;
					customer.accountNo = response.data.customer.accountNumber;
					customer.accountOwner = response.data.customer.accountOwner;
					customer.iban = response.data.customer.iban;
					customer.activeEnergyCampaignsId =
						response.data.customer.activeEnergyCampaignsId;
					customer.activeGasCampaignsId =
						response.data.customer.activeGasCampaignsId;
					customer.isCustomerEnergyTransitionInitialized = response.data.customer.isCustomerEnergyTransitionInitialized;
					customer.isCustomerEnergyTransition = response.data.customer.isCustomerEnergyTransition;
					customer.customerEnergyTransitionDate = customerEnergyTransitionDate;
					customer.isCustomerEnergyTransitionTenPercent = response.data.customer.isCustomerEnergyTransitionTenPercent;
					customer.customerEnergyTransitionTenPercentDate = customerEnergyTransitionTenPercentDate;
					customer.isCustomerGasTransitionInitialized = response.data.customer.isCustomerGasTransitionInitialized;
					customer.isCustomerGasTransition = response.data.customer.isCustomerGasTransition;
					customer.customerGasTransitionDate = customerGasTransitionDate;
					customer.isCustomerGasTransitionTenPercent = response.data.customer.isCustomerGasTransitionTenPercent;
					customer.customerGasTransitionTenPercentDate = customerGasTransitionTenPercentDate;
					customer.offeredEnergyCampaignsId =
						response.data.customer.offeredEnergyCampaignsId;
					customer.offeredGasCampaignsId =
						response.data.customer.offeredGasCampaignsId;

					dispatch({
						type: EDIT_CUSTOMER,
						payload: {
							customer: customer,
							dialogTitle: dialogTitle,
							contactPersons: response.data.contactPersons,
							contracts: response.data.customerContracts,
							customerCopies: response.data.customerCopies,
							customerObjects: response.data.customerObjects,
							administrations1: response.data.administrations1,
							administrations2: response.data.administrations2,
							administrations3: response.data.administrations3,
							energyCampaigns: response.data.energyCampaigns,
							gasCampaigns: response.data.gasCampaigns,
							isTransition: response.data.isTransition,
						},
					});

					const params = new URLSearchParams();
					params.append("jwt", sessionStorage.jwt);
					params.append("customersId", customer.id);

					let axiosConfig = {
						headers: { "Content-Type": "application/x-www-form-urlencoded" },
					};
					let url =
						process.env.REACT_APP_API_URI + "/customers/get_contracts.php";

					axios
						.post(url, params, axiosConfig)
						.then((response) => {
							if (response.status === 200) {
								//   console.log(response.data);

								const contracts = response.data.contracts;
								for (var i in contracts) {
									contracts[i].id = isNaN(contracts[i].id)
										? 0
										: Number(contracts[i].id);
								}

								dispatch({
									type: GET_CONTRACTS,
									payload: response.data.contracts,
								});
							} else if (response.status === 401) {
								window.location.href = "#/logout";
							} else {
								console.log("Anderer Fehler");
							}
						})
						.catch(function (error) {
							if (error.response) {
								// The server answered with an error
								if (error.response.status === 401) {
									window.location.href = "#/logout";
								} else {
									console.log(error.response.data);
									console.log(error.response.status);
									console.log(error.response.headers);
								}
							} else if (error.request) {
								//  The request was made but the server didn't respond
								console.log(error.request);
							} else {
								//  Something else went wrong
								console.log("Error", error.message);
							}
						});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	LOAD ALL CUSTOMERS
	const getCustomers = () => {
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("customersId", state.activeCustomerId);

		let url = process.env.REACT_APP_API_URI + "/customers/get_customers.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);
					const customers = response.data.customers;
					for (var i in customers) {
						customers[i].id = isNaN(customers[i].id)
							? 0
							: Number(customers[i].id);
						customers[i].postal_code =
							isNaN(customers[i].postal_code) || customers[i].postal_code === ""
								? 0
								: customers[i].postal_code;
					}

					dispatch({
						type: GET_CUSTOMERS,
						payload: customers,
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	SAVE A CUSTOMER
	const saveCustomerDetailDialog = async () => {

		if (state.businessPartnerTypesId <= 0) {
			return setErrMsg("Bitte wählen Sie einen Typ aus.", 1, state.activeAddressTab);
		}

		if (state.salutationsId <= 0) {
			return setErrMsg("Bitte wählen Sie eine Anrede aus.", 1, 1);
		}

		if (state.name1 === "") {
			return setErrMsg("Bitte geben Sie einen Namensteil 1 ein.", 1, 1);
		}

		if (state.country === "") {
			return setErrMsg("Bitte geben Sie ein Land an.", 1, 1);
		}

		if (state.ownerId < 0) {
			return setErrMsg("Bitte wählen Sie den Gesellschafter-Status aus.", 1, 4);
		}

		if (state.administrations1Id <= 0) {
			return setErrMsg("Bitte wählen Sie eine Verwaltung 1 aus.", 1, 4);
		}

		if (state.abcId <= 0) {
			return setErrMsg("Bitte wählen Sie einen ABC-Wert aus.", 1, state.activeAddressTab);
		}

		if (state.supervisorsId <= 0) {
			return setErrMsg("Bitte wählen Sie einen Betreuer aus.", 1, state.activeAddressTab);
		}

		if (state.isCustomerEnergyTransition === 1 && state.customerEnergyTransitionDate === '') {
			return setErrMsg("Bitte geben Sie das Datum für den Kundenübergang (Strom) an", 4, state.activeAddressTab);
		}

		if (state.isCustomerEnergyTransitionTenPercent === 1 && state.customerEnergyTransitionTenPercentDate === '') {
			return setErrMsg("Bitte geben Sie das Datum für die 10%-Regel (Strom) an.", 4, state.activeAddressTab);
		}

		if (state.isCustomerGasTransition === 1 && state.customerGasTransitionDate === '') {
			return setErrMsg("Bitte geben Sie das Datum für den Kundenübergang (Erdgas) an", 4, state.activeAddressTab);
		}

		if (state.isCustomerGasTransitionTenPercent === 1 && state.customerGasTransitionTenPercentDate === '') {
			return setErrMsg("Bitte geben Sie das Datum für die 10%-Regel (Erdgas) an.", 4, state.activeAddressTab);
		}

		//---------------------------------
		//	Check reading type vs. contact
		//	persons rules
		//---------------------------------
		const paramsIn = new URLSearchParams();
		paramsIn.append("jwt", sessionStorage.jwt);
		paramsIn.append("customerId", state.activeCustomerId);
		paramsIn.append("readingTypesId", state.readingTypesId);
		paramsIn.append("contactPersons", JSON.stringify(state.contactPersons));


		let urlIn = process.env.REACT_APP_API_URI + "/customers/check_customer_reading_type_rules.php";
		let axiosConfigIn = { headers: { "Content-Type": "application/x-www-form-urlencoded", }, };

		axios
			.post(urlIn, paramsIn, axiosConfigIn)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					if (response.data.isFine === 0) {

						return setErrMsg("Bei dieser Ableseart muss mindestes ein Ansprechpartner zur Ablesung gekennzeichnet und mit E-Mail verzeichnet sein. Bitte die Ansprechpartner prüfen.", 1, state.activeAddressTab);
					} else if (response.data.isFine === 2 && state.isReadingTypeChangeComment === false) {

						setErrMsg("", 6, 1);
						if (state.isReadingTypeChangeComment === false) {

							editContactHistory(0, 'Ableseart');
							dispatch({
								type: IS_READING_TYPE_CHANGE_COMMENT,
								payload: true,
							});
							return;
						}
					} else {

						setErrMsg("", 1, 1);

						const params = new URLSearchParams();
						params.append("jwt", sessionStorage.jwt);
						params.append("table", "customers");
						params.append("id", state.activeCustomerId);
						params.append("gp_number", state.gpNumber);
						params.append("business_partner_types_id", state.businessPartnerTypesId);
						params.append("follow_up_done", state.followUpDone);
						params.append("abc_id", state.abcId);
						params.append("is_portal_access_administration", state.isPortalAccessAdministration);
						params.append("supervisors_id", state.supervisorsId);
						params.append("supervisors_pv_id", state.supervisorsPvId);
						params.append("reading_types_id", state.readingTypesId);
						params.append("salutations_id", state.salutationsId);
						params.append("name1", state.name1);
						params.append("name2", state.name2);
						params.append("name3", state.name3);
						params.append("legal_forms_id", state.legalFormsId);
						params.append("street", state.street);
						params.append("house_number", state.houseNumber);
						params.append("address_addition", state.addressAddition);
						params.append("postal_code", state.postalCode);
						params.append("city", state.city);
						params.append("district", state.district);
						params.append("country", state.country);
						params.append("phone", state.phone);
						params.append("fax", state.fax);
						params.append("email", state.email);
						params.append("url", state.url);
						params.append("postbox", state.postbox);
						params.append("postbox_postal_code", state.postboxPostalCode);
						params.append("postbox_city", state.postboxCity);
						params.append("is_owner", state.ownerId);
						params.append("administrations_1_id", state.administrations1Id);
						params.append("administrations_2_id", state.administrations2Id);
						params.append("administrations_3_id", state.administrations3Id);
						params.append("community_code", state.communityCode);
						params.append("blz", state.bankCode);
						params.append("account_number", state.accountNo);
						params.append("account_owner", state.accountOwner);
						params.append("iban", state.iban);
						params.append("active_energy_campaigns_id", state.activeEnergyCampaignsId);
						params.append("active_gas_campaigns_id", state.activeGasCampaignsId);

						const isCEIT = (state.isCustomerEnergyTransitionInitialized > 0) ? 2 : 0;
						params.append("is_customer_energy_transition_initialized", isCEIT);
						const isCET = (state.isCustomerEnergyTransition === 1 || state.isCustomerEnergyTransition === true) ? true : false;
						params.append("is_customer_energy_transition", isCET);
						const isCETTP = (state.isCustomerEnergyTransitionTenPercent === 1 || state.isCustomerEnergyTransitionTenPercent === true) ? true : false;
						params.append("is_customer_energy_transition_ten_percent", isCETTP);

						const isCGIT = (state.isCustomerGasTransitionInitialized > 0) ? 2 : 0;
						params.append("is_customer_gas_transition_initialized", isCGIT);
						const isCGT = (state.isCustomerGasTransition === 1 || state.isCustomerGasTransition === true) ? true : false;
						params.append("is_customer_gas_transition", isCGT);
						const isCGTTP = (state.isCustomerGasTransitionTenPercent === 1 || state.isCustomerGasTransitionTenPercent === true) ? true : false;
						params.append("is_customer_gas_transition_ten_percent", isCGTTP);
						params.append(
							"offered_energy_campaigns_id",
							state.offeredEnergyCampaignsId
						);
						params.append("offered_gas_campaigns_id", state.offeredGasCampaignsId);

						let newFollowUpDate =
							state.followUpDate !== "" && state.followUpDate !== null
								? new Date(state.followUpDate)
								: "";
						if (newFollowUpDate !== "") {
							const newDay = newFollowUpDate.getDate();
							const newMonth = newFollowUpDate.getMonth() + 1;
							const newYear = newFollowUpDate.getFullYear();
							newFollowUpDate = newYear + "-" + newMonth + "-" + newDay;
						}
						params.append("follow_up_date", newFollowUpDate);

						let newCustomerEnergyTransitionDate =
							state.customerEnergyTransitionDate !== "" && state.customerEnergyTransitionDate !== null
								? new Date(state.customerEnergyTransitionDate)
								: "";

						if (newCustomerEnergyTransitionDate !== "") {
							const newETDay = newCustomerEnergyTransitionDate.getDate();
							const newETMonth = newCustomerEnergyTransitionDate.getMonth() + 1;
							const newETYear = newCustomerEnergyTransitionDate.getFullYear();
							newCustomerEnergyTransitionDate = newETYear + '-' + newETMonth + '-' + newETDay;
						}
						params.append("customer_energy_transition_date", newCustomerEnergyTransitionDate);

						let newCustomerEnergyTransitionTenPercentDate = state.customerEnergyTransitionTenPercentDate !== "" && state.customerEnergyTransitionTenPercentDate !== null ? new Date(state.customerEnergyTransitionTenPercentDate) : "";

						if (newCustomerEnergyTransitionTenPercentDate !== "") {
							const newETTPDDay = newCustomerEnergyTransitionTenPercentDate.getDate();
							const newETTPMonth = newCustomerEnergyTransitionTenPercentDate.getMonth() + 1;
							const newETTPYear = newCustomerEnergyTransitionTenPercentDate.getFullYear();
							newCustomerEnergyTransitionTenPercentDate = newETTPYear + '-' + newETTPMonth + '-' + newETTPDDay;
						}
						params.append("customer_energy_transition_ten_percent_date", newCustomerEnergyTransitionTenPercentDate);

						let newCustomerGasTransitionDate =
							state.customerGasTransitionDate !== "" && state.customerGasTransitionDate !== null
								? new Date(state.customerGasTransitionDate)
								: "";

						if (newCustomerGasTransitionDate !== "") {
							const newGTDay = newCustomerGasTransitionDate.getDate();
							const newGTMonth = newCustomerGasTransitionDate.getMonth() + 1;
							const newGTYear = newCustomerGasTransitionDate.getFullYear();
							newCustomerGasTransitionDate = newGTYear + '-' + newGTMonth + '-' + newGTDay;
						}
						params.append("customer_gas_transition_date", newCustomerGasTransitionDate);

						let newCustomerGasTransitionTenPercentDate = state.customerGasTransitionTenPercentDate !== "" && state.customerGasTransitionTenPercentDate !== null ? new Date(state.customerGasTransitionTenPercentDate) : "";

						if (newCustomerGasTransitionTenPercentDate !== "") {
							const newGTTPDay = newCustomerGasTransitionTenPercentDate.getDate();
							const newGTTPMonth = newCustomerGasTransitionTenPercentDate.getMonth() + 1;
							const newGTTPYear = newCustomerGasTransitionTenPercentDate.getFullYear();
							newCustomerGasTransitionTenPercentDate = newGTTPYear + '-' + newGTTPMonth + '-' + newGTTPDay;
						}
						params.append("customer_gas_transition_ten_percent_date", newCustomerGasTransitionTenPercentDate);

						let url = process.env.REACT_APP_API_URI + "/customers/set_customer.php";
						let axiosConfig = {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						};

						axios
							.post(url, params, axiosConfig)
							.then((response) => {
								if (response.status === 200) {
									// console.log(response.data);

									closeCustomerDetailDialog();
									getCustomers();
								} else if (response.status === 401) {
									window.location.href = "#/logout";
								} else {
									console.log("Anderer Fehler");
								}
							})
							.catch(function (error) {
								if (error.response) {
									// The server answered with an error
									if (error.response.status === 401) {
										window.location.href = "#/logout";
									} else {
										console.log(error.response.data);
										console.log(error.response.status);
										console.log(error.response.headers);
									}
								} else if (error.request) {
									//  The request was made but the server didn't respond
									console.log(error.request);
								} else {
									//  Something else went wrong
									console.log("Error", error.message);
								}
							});
					}
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});

	};

	const setErrMsg = (msg, tab, addressTab) => {
		dispatch({
			type: SET_ERR_MSG,
			payload: {
				customerDetailDialogError: msg,
				leadDetailDialogActiveTab: tab,
				activeAddressTab: addressTab,
			},
		});
	};

	const setContractErrMsg = (msg, tab) => {
		dispatch({
			type: SET_CONTRACT_ERR_MSG,
			payload: {
				customerContractsDialogError: msg,
				activeContractTab: tab,
			}
		});
	}

	//	CHANGE ACTIVE TAB OF CUSTOMER'S DETAIL DIALOG
	const setCustomerDetailDialogActiveTab = (tab) => {
		dispatch({
			type: SET_CUSTOMER_DETAIL_DIALOG_ACTIVE_TAB,
			payload: tab,
		});
	};

	//	SET THE FOREIGN VALUES FOR THE CUSTOMER'S DIALOG
	const setForeigns = (
		businessPartnerTypes,
		abc,
		supervisors,
		supervisorsPv,
		salutations,
		yesNos,
		administrations1,
		administrations2,
		administrations3,
		energyCampaigns,
		gasCampaigns,
		divisions,
		changeReasons,
		suppliers,
		networks,
		loadProfiles,
		legalForms,
		readingTypes,
		customerTypes,
		terminationReasons
	) => {
		//   console.log(terminationReasons);

		dispatch({
			type: SET_FOREIGNS,
			payload: {
				businessPartnerTypes: businessPartnerTypes,
				abc: abc,
				supervisors: supervisors,
				supervisorsPv: supervisorsPv,
				salutations: salutations,
				yesNos: yesNos,
				administrations1: administrations1,
				administrations2: administrations2,
				administrations3: administrations3,
				energyCampaigns: energyCampaigns,
				gasCampaigns: gasCampaigns,
				divisions: divisions,
				changeReasons: changeReasons,
				suppliers: suppliers,
				networks: networks,
				loadProfiles: loadProfiles,
				legalForms: legalForms,
				readingTypes: readingTypes,
				customerTypes: customerTypes,
				terminationReasons: terminationReasons,
			},
		});
	};

	//---------------------------------
	//	CUSTOMER ADDRESS
	//---------------------------------

	//	ABC FLAG CHANGE
	const abcIdChange = (e) => {
		dispatch({
			type: ABC_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//	BUSINESS PARTNER TYPE CHANGE
	const businessPartnerTypesIdChange = (e) => {
		dispatch({
			type: BUSINESS_PARTNER_TYPES_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE OF THE FOLLOW UP DATE
	const followUpDateChange = (date) => {
		dispatch({
			type: FOLLOW_UP_DATE_CHANGE,
			payload: date,
		});
	};

	//	CHANGE THE FOLLOW UP STATE
	const followUpDoneChange = (e) => {
		dispatch({
			type: FOLLOW_UP_DONE_CHANGE,
			payload: e.target.checked,
		});
	};

	//	GO TO CUSTOMER'S LEAD COPY
	const goToCopy = async (targetRoute) => {

		await closeCustomerDetailDialog();

		let hrefArr = window.location.href.split("/#");
		let hrefPage = hrefArr[1];
		let hrefSearch = "";
		if (hrefArr[1].indexOf("?") !== -1) {
			let hrefUrl = hrefArr[1].split("?");
			hrefPage = hrefUrl[0];
			hrefSearch = hrefUrl[1];
		}

		let targetPage = targetRoute;
		let targetSearch = "";
		if (targetPage.indexOf("?") !== -1) {
			let targetUrl = targetPage.split("?");
			targetPage = targetUrl[0];
			targetSearch = targetUrl[1];
		}

		if (hrefPage === targetPage) {
			if (hrefSearch === "")
				window.location.href = window.location.href + "?" + targetSearch;
			else
				window.location.href = window.location.href.replace(
					hrefSearch,
					targetSearch
				);
			window.location.reload();
		}
	}

	//	CHANGE THE PORTAL ACCESS FLAG
	const isPortalAccessAdministrationChange = (e) => {
		dispatch({
			type: IS_PORTAL_ACCESS_ADMINISTRATION_CHANGE,
			payload: e.target.checked,
		});
	};

	//	SET THE ACTIVE CUSTOMER ADDRESS TAB
	const setActiveAddressTab = (tab) => {
		dispatch({
			type: SET_ACTIVE_ADDRESS_TAB,
			payload: tab,
		});
	};

	//	CHANGE THE ID OF THE SUPERVISOR
	const supervisorsIdChange = (e) => {
		dispatch({
			type: SUPERVISORS_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE THE ID OF THE SUPERVISOR PV
	const supervisorsPvIdChange = (e) => {
		dispatch({
			type: SUPERVISORS_PV_ID_CHANGE,
			payload: e.target.value,
		});
	}

	//----------------------------------
	//	CUSTOMER OBJECT LIST DIALOG
	//----------------------------------
	const closeCustomerObjectListDialog = () => {
		dispatch({
			type: CUSTOMER_OBJECT_LIST_DIALOG,
			payload: false,
		});
	};

	const copyCustomer = async () => {

		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("customerId", state.activeCustomerId);

		let url = process.env.REACT_APP_API_URI + "/customers/get_active_contracts.php";
		let axiosConfig = {
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
		};

		await axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					dispatch({
						type: CUSTOMER_COPY_DIALOG,
						payload: {
							customerCopyDialogState: true,
							customerCopyDialogId: response.data.newCustomerId,
							contactPersons: []
						}
					});


				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					//	The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//	The request was made but the server didn't responsd
					console.log(error.request);
				} else {
					//	Something else went wrong
					console.log("Error", error.message);
				}
			});
	}

	const loadObjectlist = async (division) => {
		dispatch({
			type: CUSTOMER_OBJECT_LIST_DIALOG,
			payload: true,
		});

		//	Start the export of the object list
		let activeEntries = "single-" + state.activeCustomerId;

		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("activeEntries", activeEntries);
		params.append("divisionsId", division);
		params.append("customerOption", 1);
		params.append("leadOption", 0);
		params.append("file", "");

		let url = process.env.REACT_APP_API_URI + "/export_object_list.php";
		let axiosConfig = {
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
		};

		await axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {

					//	Check for finished export
					// console.log(response.data);
					checkObjectList(division);

				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					//	The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//	The request was made but the server didn't responsd
					console.log(error.request);
				} else {
					//	Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	const checkObjectList = async (division) => {

		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("customerId", state.activeCustomerId);
		params.append("divisionsId", division);

		let url = process.env.REACT_APP_API_URI + "/check_object_list.php";
		let axiosConfig = {
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
		};

		await axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					if (response.data.isFinished === 1) {
						window.clearTimeout();
						dispatch({
							type: CUSTOMER_OBJECT_LIST_IS_FINISHED,
							payload: {
								customerObjectListIsFinished: true,
								customerObjectListIsEmpty: response.data.isEmpty === 1 ? true : false,
								customerObjectListDivision: response.data.division,
							}
						});
					} else window.setTimeout(() => checkObjectList(division), 1000);
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					//	The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//	The request was made but the server didn't responsd
					console.log(error.request);
				} else {
					//	Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//---------------------------------
	//	CUSTOMER ADDRESS ADDRESS
	//---------------------------------

	//	CHANGE ADDRESS ADDITION
	const addressAdditionalChange = (e) => {
		dispatch({
			type: ADDRESS_ADDITION_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CITY
	const cityChange = (e) => {
		dispatch({
			type: CITY_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE COUNTRY
	const countryChange = (e) => {
		dispatch({
			type: COUNTRY_CHANGE,
			payload: e.target.value,
		});
	};

	//	DISTRICT CHANGE
	const districtChange = (e) => {
		dispatch({
			type: DISTRICT_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE HOUSE NUMBER
	const houseNumberChange = (e) => {
		dispatch({
			type: HOUSE_NUMBER_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE NAME PART 1
	const name1Change = (e) => {
		dispatch({
			type: NAME_1_CHANGE,
			payload: e.target.value.substring(0, 40),
		});
	};

	//	CHANGE NAME PART 2
	const name2Change = (e) => {
		dispatch({
			type: NAME_2_CHANGE,
			payload: e.target.value.substring(0, 40),
		});
	};

	//	CHANGE NAME PART 3
	const name3Change = (e) => {
		dispatch({
			type: NAME_3_CHANGE,
			payload: e.target.value.substring(0, 40),
		});
	};

	//	CHANGE LEGAL FORM
	const legalFormsIdChange = (e) => {
		dispatch({
			type: LEGAL_FORMS_ID_CHANGE,
			payload: e.target.value,
		});
	}

	//	CHANGE THE POSTAL CODE
	const postalCodeChange = (e) => {
		dispatch({
			type: POSTAL_CODE_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE THE READING TYPE
	const readingTypesIdChange = (e) => {
		dispatch({
			type: READING_TYPES_ID_CHANGE,
			payload: e.target.value,
		});
	}

	//	CHANGE THE SALUTATION
	const salutationsIdChange = (e) => {
		dispatch({
			type: SALUTATIONS_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE THE STREET
	const streetChange = (e) => {
		dispatch({
			type: STREET_CHANGE,
			payload: e.target.value,
		});
	};

	//----------------------------------
	//	CUSTOMER ADDRESS CONTACT
	//----------------------------------

	//	CHANGE EMAIL
	const emailChange = (e) => {
		dispatch({
			type: EMAIL_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE FAX
	const faxChange = (e) => {
		dispatch({
			type: FAX_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE PHONE
	const phoneChange = (e) => {
		dispatch({
			type: PHONE_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE URL
	const urlChange = (e) => {
		dispatch({
			type: URL_CHANGE,
			payload: e.target.value,
		});
	};

	//---------------------------------
	//	CUSTOMER ADDRESS POSTBOX
	//---------------------------------

	//	CHANGE POSTBOX
	const postboxChange = (e) => {
		dispatch({
			type: POSTBOX_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE POSTBOX CITY
	const postboxCityChange = (e) => {
		dispatch({
			type: POSTBOX_CITY_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE POSTBOX POSTAL CODE
	const postboxPostalCodeChange = (e) => {
		dispatch({
			type: POSTBOX_POSTAL_CODE_CHANGE,
			payload: e.target.value,
		});
	};

	//-------------------------------------
	//	CUSTOMER ADDRESS ADMINISTRATION
	//-------------------------------------

	//	CHANGE ADMINISTRATION 1
	const administrations1IdChange = (e) => {
		const admin1 = e.target.value;

		dispatch({
			type: ADMINISTRATIONS_1_ID_CHANGE,
			payload: admin1,
		});

		//  Get administrations 2 & 3
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("administration1", admin1);

		let url =
			process.env.REACT_APP_API_URI + "/customers/get_administration2.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					dispatch({
						type: GET_ADMINISTRATIONS_2,
						payload: {
							administrations2: response.data.administrations2,
							administrations3: response.data.administrations3,
						},
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	CHANGE ADMINISTRATION 2
	const administrations2IdChange = (e) => {
		const admin2 = e.target.value;

		dispatch({
			type: ADMINISTRATIONS_2_ID_CHANGE,
			payload: admin2,
		});

		//  Get administrations 3
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("administration2", admin2);

		let url =
			process.env.REACT_APP_API_URI + "/customers/get_administration3.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					dispatch({
						type: GET_ADMINISTRATIONS_3,
						payload: {
							administrations3: response.data.administrations3,
						},
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	CHANGE ADMINISTRATION 3
	const administrations3IdChange = (e) => {
		dispatch({
			type: ADMINISTRATIONS_3_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE COMMUNITY CODE
	const communityCodeChange = (e) => {
		dispatch({
			type: COMMUNITY_CODE_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE OWNER ID
	const ownerIdChange = (e) => {
		const ownerId = e.target.value;

		dispatch({
			type: OWNER_ID_CHANGE,
			payload: ownerId,
		});

		//  Get administrations 1
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("ownerId", ownerId);

		let url =
			process.env.REACT_APP_API_URI + "/customers/get_administration1.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					dispatch({
						type: GET_ADMINISTRATIONS_1,
						payload: {
							administrations1: response.data.administrations1,
							administrations2: response.data.administrations2,
							administrations3: response.data.administrations3,
						},
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//-------------------------------------
	//	CUSTOMER CONTACT PERSONS
	//-------------------------------------

	//	CLOSE THE CONTACT PERSON DETAIL DIALOG
	const closeContactPersonDialog = () => {
		dispatch({
			type: CLOSE_CONTACT_PERSON_DIALOG,
		});
	};

	//	CLOSE DELETE MODAL FOR CONTACT
	const closeDelete = () => {
		dispatch({
			type: CLOSE_DELETE,
		});
	};

	//	CHANGE DIVISION OF CONTACT
	const contactPersonDivisionChange = (e) => {
		dispatch({
			type: CONTACT_PERSON_DIVISION_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE EMAIL OF CONTACT
	const contactPersonEmailChange = (e) => {

		if (state.contactPersonDetailsDialogError !== "" && state.contactPersonIsInfoReading === true && state.contactPersonEmail === "") {
			setContactPersonError("");
		}

		dispatch({
			type: CONTACT_PERSON_EMAIL_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTACT'S FAX
	const contactPersonFaxChange = (e) => {
		dispatch({
			type: CONTACT_PERSON_FAX_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE FIRST NAME OF CONTACT
	const contactPersonFirstnameChange = (e) => {
		if (
			state.contactPersonDetailsDialogError !== "" &&
			state.contactPersonFirstname === ""
		)
			setContactPersonError("");

		dispatch({
			type: CONTACT_PERSON_FIRSTNAME_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE FUNCTION OF CONTACT
	const contactPersonFunctionChange = (e) => {
		dispatch({
			type: CONTACT_PERSON_FUNCTION_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE PORTAL ACCESS FLAG OF CONTACT
	const contactPersonIsPortalAccessChange = (e) => {
		dispatch({
			type: CONTACT_PERSON_IS_PORTAL_ACCESS_CHANGE,
			payload: e.target.checked,
		});
	};

	//	CHANGE LAST NAME OF CONTACT
	const contactPersonLastnameChange = (e) => {
		if (
			state.contactPersonDetailsDialogError !== "" &&
			state.contactPersonLastname === ""
		)
			setContactPersonError("");

		dispatch({
			type: CONTACT_PERSON_LASTNAME_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTACT'S MOBILE
	const contactPersonMobileChange = (e) => {
		dispatch({
			type: CONTACT_PERSON_MOBILE_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE IS INFO READING
	const contactPersonIsInfoReadingChange = (e) => {

		if (state.contactPersonDetailsDialogError !== "" && state.contactPersonIsInfoReading === true && state.contactPersonEmail === "") {
			setContactPersonError("");
		}

		dispatch({
			type: CONTACT_PERSON_IS_INFO_READING_CHANGE,
			payload: e.target.checked,
		});
	}

	//	CHANGE CONTACT PHONE
	const contactPersonPhoneChange = (e) => {
		dispatch({
			type: CONTACT_PERSON_PHONE_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE PORTAL ACCESS VALIDITY OF CONTACT
	const contactPersonPortalAccessValidToChange = (date) => {
		dispatch({
			type: CONTACT_PERSON_PORTAL_ACCESS_VALID_TO_CHANGE,
			payload: date,
		});
	};

	//	CHANGE CONTACT SALUTATION
	const contactPersonSalutationsIdChange = (e) => {
		if (
			state.contactPersonDetailsDialogError !== "" &&
			state.contactPersonSalutationsId <= 0
		)
			setContactPersonError("");

		dispatch({
			type: CONTACT_PERSON_SALUTATIONS_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTACT TITLE
	const contactPersonTitleChange = (e) => {
		dispatch({
			type: CONTACT_PERSON_TITLE_CHANGE,
			payload: e.target.value,
		});
	};

	//	CONFIRM TO DELETE CONTACT PERSON
	const deleteContactPersonConfirm = (id) => {
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", id);

		let url =
			process.env.REACT_APP_API_URI + "/customers/get_contact_person.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);
					const entityName =
						"Möchten Sie den Ansprechpartner " +
						response.data.contactPerson.firstname +
						" " +
						response.data.contactPerson.lastname +
						" endgültig löschen?";
					dispatch({
						type: DELETE_CONTACT_PERSON_CONFIRM,
						payload: {
							entityName: entityName,
							title: "Ansprechpartner löschen",
							id: id,
						},
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	DELETE CONTACT PERSON
	const deleteEntityConfirmed = () => {
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", state.activeContactPerson);

		let url =
			process.env.REACT_APP_API_URI + "/customers/delete_contact_person.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);
					dispatch({
						type: DELETE_CONTACT_PERSON,
					});
					getContactPersons();
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	EDIT OR CREATE CONTACT PERSON
	const editContactPerson = (id) => {
		let dialogTitle = "Neue Kontaktperson anlegen";

		if (id > 0) {
			dialogTitle = "Kontaktperson bearbeiten";
			const params = new URLSearchParams();
			params.append("jwt", sessionStorage.jwt);
			params.append("id", id);

			let url =
				process.env.REACT_APP_API_URI + "/customers/get_contact_person.php";
			let axiosConfig = {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			};

			axios
				.post(url, params, axiosConfig)
				.then((response) => {
					if (response.status === 200) {
						if (response.data.contactPerson.portalAccessValidTo)
							response.data.contactPerson.portalAccessValidTo = new Date(
								response.data.contactPerson.portalAccessValidTo
							);

						// console.log(response.data);
						dispatch({
							type: SET_ACTIVE_CONTACT_PERSON,
							payload: response.data.contactPerson,
						});
					} else if (response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log("Anderer Fehler");
					}
				})
				.catch(function (error) {
					if (error.response) {
						// The server answered with an error
						if (error.response.status === 401) {
							window.location.href = "#/logout";
						} else {
							console.log(error.response.data);
							console.log(error.response.status);
							console.log(error.response.headers);
						}
					} else if (error.request) {
						//  The request was made but the server didn't respond
						console.log(error.request);
					} else {
						//  Something else went wrong
						console.log("Error", error.message);
					}
				});
		}

		dispatch({
			type: EDIT_CONTACT_PERSON,
			payload: {
				activeContactPerson: id,
				contactPersonDetailsDialogState: true,
				contactPersonDetailsDialogTitle: dialogTitle,
			},
		});
	};

	//	GET THE CONTACT PERSONS FOR THE CUSTOMER
	const getContactPersons = () => {
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("customersId", state.activeCustomerId);

		let url =
			process.env.REACT_APP_API_URI + "/customers/get_contact_persons.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					dispatch({
						type: GET_CONTACT_PERSONS,
						payload: response.data.contactPersons,
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	SAVE THE CONTACT PERSON
	const saveContactPerson = () => {

		const portalAccessValidTo = state.contactPersonPortalAccessValidTo !== "" ? new Date(state.contactPersonPortalAccessValidTo) : "";
		let newPortalAccessValidTo = "";

		if (portalAccessValidTo !== "") {

			const newDay = portalAccessValidTo.getDate();
			const newMonth = portalAccessValidTo.getMonth() + 1;
			const newYear = portalAccessValidTo.getFullYear();
			newPortalAccessValidTo = newYear + "-" + newMonth + "-" + newDay;
		}

		if (state.contactPersonFirstname === "") {
			setContactPersonError("Bitte geben Sie einen Vornamen für die Kontaktperson ein");
			return false;
		}

		if (state.contactPersonLastname === "") {
			setContactPersonError("Bitte geben Sie einen Nachnamen für die Kontaktperson ein");
			return false;
		}

		if (state.contactPersonSalutationsId <= 0) {
			setContactPersonError("Bitte wählen Sie eine Anrede für die Kontaktperson aus");
			return false;
		}

		if (state.contactPersonIsInfoReading === true && state.contactPersonEmail === "") {
			setContactPersonError("Bei aktiver Ablese-Information muss eine E-Mail-Adresse hinterlegt werden.");
			return false;
		}

		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", state.activeContactPerson);
		params.append("customersId", state.activeCustomerId);
		params.append("salutationsId", state.contactPersonSalutationsId);
		params.append("title", state.contactPersonTitle);
		params.append("firstname", state.contactPersonFirstname);
		params.append("lastname", state.contactPersonLastname);
		params.append("isInfoReading", state.contactPersonIsInfoReading);
		params.append("phone", state.contactPersonPhone);
		params.append("fax", state.contactPersonFax);
		params.append("mobile", state.contactPersonMobile);
		params.append("email", state.contactPersonEmail);
		params.append("function", state.contactPersonFunction);
		params.append("division", state.contactPersonDivision);
		params.append("isPortalAccess", state.contactPersonIsPortalAccess);
		params.append("portalAccessValidTo", newPortalAccessValidTo);

		let url =
			process.env.REACT_APP_API_URI + "/customers/set_contact_person.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);
					getContactPersons();

					dispatch({
						type: CLOSE_CONTACT_PERSON_DIALOG,
					});
					return true;
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
					return false;
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
						return false;
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
					return false;
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
					return false;
				}
			});
		return true;
	};

	//	SET ERROR IN CONTACT PERSON DETAIL DIALOG
	const setContactPersonError = (errMsg) => {
		dispatch({
			type: SET_CONTACT_PERSON_ERROR,
			payload: errMsg,
		});
	};

	//--------------------------------------
	//	CUSTOMER BANK
	//--------------------------------------

	//	CHANGE ACCOUNT NUMBER
	const accountNoChange = (e) => {
		dispatch({
			type: ACCOUNT_NO_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE ACCOUNT OWNER
	const accountOwnerChange = (e) => {
		dispatch({
			type: ACCOUNT_OWNER_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE BANK CODE
	const bankCodeChange = (e) => {
		dispatch({
			type: BANK_CODE_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE IBAN
	const ibanChange = (e) => {
		dispatch({
			type: IBAN_CHANGE,
			payload: e.target.value,
		});
	};

	//---------------------------------------
	//	CUSTOMER PRODUCTS
	//---------------------------------------
	const setActiveProductTab = (tab) => {
		dispatch({
			type: SET_ACTIVE_PRODUCT_TAB,
			payload: tab,
		});
	};

	//	CHANGE ACTIVE ENERGY CAMPAIGN
	const activeEnergyCampaignsIdChange = (e) => {

		const newEnergyCampaignId = e.target.value;

		const isCustomerEnergyTransitionInitialized = parseInt(state.isCustomerEnergyTransitionInitialized) < 2 ? 1 : state.isCustomerEnergyTransitionInitialized;
		const isCustomerEnergyInitialTransition = parseInt(state.isCustomerEnergyTransitionInitialized) < 2 ? 1 : state.isCustomerEnergyInitialTransition;
		const isCustomerEnergyTransition = (isCustomerEnergyInitialTransition === 1) ? 1 : state.isCustomerEnergyTransition;
		const isCustomerEnergyTransitionTenPercent = (isCustomerEnergyInitialTransition === 1) ? 1 : state.isCustomerEnergyTransitionTenPercent;

		if (parseInt(state.activeEnergyCampaignsId) === 0) {
			dispatch({
				type: ACTIVE_ENERGY_CAMPAIGNS_ID_CHANGE,
				payload: {
					newEnergyCampaignId: newEnergyCampaignId,
					customerActiveCampaignChangeLoading: false,
					customerActiveCampaignChangeContracts: [],
					customerActiveCampaignChangeFileName: '',
					isCustomerEnergyTransitionInitialized: isCustomerEnergyTransitionInitialized,
					isCustomerEnergyInitialTransition: isCustomerEnergyInitialTransition,
					isCustomerEnergyTransition: isCustomerEnergyTransition,
					isCustomerEnergyTransitionTenPercent: isCustomerEnergyTransitionTenPercent,
				}
			});
		}
		else {
			dispatch({
				type: CUSTOMER_ACTIVE_CAMPAIGN_CHANGE_DIALOG,
				payload: {
					customerActiveCampaignChangeDialogState: true,
				},
			});

			const params = new URLSearchParams();
			params.append("jwt", sessionStorage.jwt);
			params.append("divisionsId", 2);
			params.append("customersId", state.activeCustomerId);
			params.append("oldActiveCampaignId", state.activeEnergyCampaignsId);
			params.append("newActiveCampaignId", newEnergyCampaignId);

			let url =
				process.env.REACT_APP_API_URI +
				"/customers/change_active_campaign.php";
			let axiosConfig = {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			};

			axios
				.post(url, params, axiosConfig)
				.then((response) => {
					if (response.status === 200) {
						// console.log(response.data);

						dispatch({
							type: ACTIVE_ENERGY_CAMPAIGNS_ID_CHANGE,
							payload: {
								newEnergyCampaignId: newEnergyCampaignId,
								customerActiveCampaignChangeLoading: false,
								customerActiveCampaignChangeContracts: response.data.contracts,
								customerActiveCampaignChangeFileName: response.data.filename,
								isCustomerEnergyTransitionInitialized: isCustomerEnergyTransitionInitialized,
								isCustomerEnergyInitialTransition: isCustomerEnergyInitialTransition,
								isCustomerEnergyTransition: isCustomerEnergyTransition,
								isCustomerEnergyTransitionTenPercent: isCustomerEnergyTransitionTenPercent,
							}
						});
					} else if (response.status === 401) {
						console.log('1');
						window.location.href = "#/logout";
					} else {
						console.log("Anderer Fehler");
					}
				})
				.catch(function (error) {
					if (error.response) {
						// The server answered with an error
						if (error.response.status === 401) {
							console.log('2');
							console.log(error);
							// window.location.href = "#/logout";
						} else {
							console.log(error.response.data);
							console.log(error.response.status);
							console.log(error.response.headers);
						}
					} else if (error.request) {
						//  The request was made but the server didn't respond
						console.log(error.request);
					} else {
						//  Something else went wrong
						console.log("Error", error.message);
					}
				});
		}
	};

	//	CHANGE ACTIVE GAS CAMPAIGN
	const activeGasCampaignsIdChange = (e) => {

		const newGasCampaignId = e.target.value;

		const isCustomerGasTransitionInitialized = parseInt(state.isCustomerGasTransitionInitialized) < 2 ? 1 : state.isCustomerGasTransitionInitialized;
		const isCustomerGasInitialTransition = parseInt(state.isCustomerGasTransitionInitialized) < 2 ? 1 : state.isCustomerGasInitialTransition;
		const isCustomerGasTransition = (isCustomerGasInitialTransition === 1) ? 1 : state.isCustomerGasTransition;
		const isCustomerGasTransitionTenPercent = (isCustomerGasInitialTransition === 1) ? 1 : state.isCustomerGasTransitionTenPercent;


		if (parseInt(state.activeGasCampaignsId) === 0) {
			dispatch({
				type: ACTIVE_GAS_CAMPAIGNS_ID_CHANGE,
				payload: {
					newGasCampaignId: newGasCampaignId,
					customerActiveCampaignChangeLoading: false,
					customerActiveCampaignChangeContracts: [],
					customerActiveCampaignChangeFileName: '',
					isCustomerGasTransitionInitialized: isCustomerGasTransitionInitialized,
					isCustomerGasInitialTransition: isCustomerGasInitialTransition,
					isCustomerGasTransition: isCustomerGasTransition,
					isCustomerGasTransitionTenPercent: isCustomerGasTransitionTenPercent,
				}
			});
		}
		else {
			dispatch({
				type: CUSTOMER_ACTIVE_CAMPAIGN_CHANGE_DIALOG,
				payload: {
					customerActiveCampaignChangeDialogState: true,
				}
			});

			const params = new URLSearchParams();
			params.append("jwt", sessionStorage.jwt);
			params.append("divisionsId", 1);
			params.append("customersId", state.activeCustomerId);
			params.append("oldActiveCampaignId", state.activeGasCampaignsId);
			params.append("newActiveCampaignId", newGasCampaignId);

			let url =
				process.env.REACT_APP_API_URI +
				"/customers/change_active_campaign.php";
			let axiosConfig = {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			};

			axios
				.post(url, params, axiosConfig)
				.then((response) => {
					if (response.status === 200) {
						// console.log(response.data);

						dispatch({
							type: ACTIVE_GAS_CAMPAIGNS_ID_CHANGE,
							payload: {
								newGasCampaignId: newGasCampaignId,
								customerActiveCampaignChangeLoading: false,
								customerActiveCampaignChangeContracts: response.data.contracts,
								customerActiveCampaignChangeFileName: response.data.filename,
								isCustomerGasTransitionInitialized: isCustomerGasTransitionInitialized,
								isCustomerGasInitialTransition: isCustomerGasInitialTransition,
								isCustomerGasTransition: isCustomerGasTransition,
								isCustomerGasTransitionTenPercent: isCustomerGasTransitionTenPercent,
							}
						});
					} else if (response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log("Anderer Fehler");
					}
				})
				.catch(function (error) {
					if (error.response) {
						// The server answered with an error
						if (error.response.status === 401) {
							window.location.href = "#/logout";
						} else {
							console.log(error.response.data);
							console.log(error.response.status);
							console.log(error.response.headers);
						}
					} else if (error.request) {
						//  The request was made but the server didn't respond
						console.log(error.request);
					} else {
						//  Something else went wrong
						console.log("Error", error.message);
					}
				});
		}
	};


	//	CHANGE THE ENERGY TRANSITION FLAG
	const isCustomerEnergyTransitionChange = (e) => {
		const isChange = (e.target.checked === false) ? 0 : 1;
		dispatch({
			type: IS_CUSTOMER_ENERGY_TRANSITION_CHANGE,
			payload: isChange,
		});
	}


	//	CHANGE THE ENERGY TRANSITION DATE
	const customerEnergyTransitionDateChange = (date) => {
		dispatch({
			type: CUSTOMER_ENERGY_TRANSITION_DATE_CHANGE,
			payload: date,
		});
	}

	//	CHANGE THE ENERGY 10% TRANSIITON FLAG
	const isCustomerEnergyTransitionTenPercentChange = (e) => {
		const isChange = (e.target.checked === false) ? 0 : 1;
		dispatch({
			type: IS_CUSTOMER_ENERGY_TRANSITION_TEN_PERCENT_CHANGE,
			payload: isChange,
		});
	}

	//	CHANGE THE ENERGY 10% TRANSITION DATE
	const customerEnergyTransitionTenPercentDateChange = (date) => {
		dispatch({
			type: CUSTOMER_ENERGY_TRANSITION_TEN_PERCENT_DATE_CHANGE,
			payload: date,
		});
	}

	//	CHANGE THE GAS TRANSITION FLAG
	const isCustomerGasTransitionChange = (e) => {
		const isChange = (e.target.checked === false) ? 0 : 1;
		dispatch({
			type: IS_CUSTOMER_GAS_TRANSITION_CHANGE,
			payload: isChange,
		});
	}


	//	CHANGE THE GAS TRANSITION DATE
	const customerGasTransitionDateChange = (date) => {
		dispatch({
			type: CUSTOMER_GAS_TRANSITION_DATE_CHANGE,
			payload: date,
		});
	}

	//	CHANGE THE GAS 10% TRANSITION FLAG
	const isCustomerGasTransitionTenPercentChange = (e) => {
		const isChange = (e.target.checked === false) ? 0 : 1;
		dispatch({
			type: IS_CUSTOMER_GAS_TRANSITION_TEN_PERCENT_CHANGE,
			payload: isChange,
		});
	}


	//	CHANGE THE GAS 10% TRANSITION DATE
	const customerGasTransitionTenPercentDateChange = (date) => {
		dispatch({
			type: CUSTOMER_GAS_TRANSITION_TEN_PERCENT_DATE_CHANGE,
			payload: date,
		});
	}

	//	CHANGE OFFERED ENERGY CAMPAIGN
	const offeredEnergyCampaignsIdChange = (e) => {
		dispatch({
			type: OFFERED_ENERGY_CAMPAIGNS_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE OFFERED GAS CAMPAIGN
	const offeredGasCampaignsIdChange = (e) => {
		dispatch({
			type: OFFERED_GAS_CAMPAIGNS_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//	GET PRODUCT HISTORY ENTRIES
	const getProductHistories = () => {
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("customerId", state.activeCustomerId);

		let url =
			process.env.REACT_APP_API_URI +
			"/customers/get_customer_product_histories.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					dispatch({
						type: GET_PRODUCT_HISTORIES,
						payload: response.data.customerProductHistories,
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//---------------------------------------
	//	CUSTOMER CONTRACTS
	//---------------------------------------

	//	CLOSE CONTRACT DIALOG
	const closeContractsDialog = () => {
		dispatch({
			type: CLOSE_CONTRACTS_DIALOG,
		});
	};

	//	CLOSE CONTRACT DELETE CONFIRM DIALOG
	const closeDeleteContract = () => {
		dispatch({
			type: CLOSE_DELETE_CONTRACT,
		});
	};

	//	DELETE CUSTOMER CONTRACT
	const deleteContract = () => {
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", state.activeCustomerContract);

		let url = process.env.REACT_APP_API_URI + "/customers/delete_contract.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);
					closeDeleteContract();
					getContracts();
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	CUSTOMER CONTRACT CONFIRM DIALOG
	const deleteCustomerContract = (id) => {
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", id);

		let url =
			process.env.REACT_APP_API_URI + "/customers/get_customer_contract.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					const entityName =
						"Möchten Sie die Abnahmestelle mit der Objekt-Id " +
						response.data.id +
						" endgültig löschen?";

					dispatch({
						type: DELETE_CONTRACT_CONFIRM,
						payload: {
							entityName: entityName,
							title: "Abnahmestelle löschen",
							id: id,
						},
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	EDIT A CUSTOMER'S CONTRACT
	const editCustomerContract = (id) => {

		if (state.bIsCustomerEnergyTransitionChange === true || state.bIsCustomerEnergyTransitionTenPercentChange === true || state.bIsCustomerGasTransitionChange === true || state.bIsCustomerGasTransitionTenPercentChange === true) {
			return setErrMsg(
				"Kundenübergang wurde verändert. Damit diese Änderungen auf die Verträge übernommen werden können, muss der Kunde gespeichert oder die Änderung verworfen werden.",
				4,
				state.activeAddressTab,
			);
		}

		if (id > 0) {

			const params = new URLSearchParams();
			params.append("jwt", sessionStorage.jwt);
			params.append("id", id);
			params.append("gasCampaignsId", state.activeGasCampaignsId);
			params.append("energyCampaignsId", state.activeEnergyCampaignsId);
			params.append("activeCustomerContract", state.activeCustomerContract);

			let url =
				process.env.REACT_APP_API_URI + "/customers/get_customer_contract.php";
			let axiosConfig = {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			};

			axios
				.post(url, params, axiosConfig)
				.then((response) => {
					if (response.status === 200) {
						// console.log(response.data);

						let customerContractTransitionDate = "";
						if (response.data.customerContract.customerContractTransitionDate !== "") {
							customerContractTransitionDate = new Date(response.data.customerContract.customerContractTransitionDate);
						}

						let customerContractTransitionTenPercentDate = "";
						if (response.data.customerContract.customerContractTransitionTenPercentDate !== "") {
							customerContractTransitionTenPercentDate = new Date(response.data.customerContract.customerContractTransitionTenPercentDate);
						}

						let supplyStart = "";
						if (response.data.customerContract.customerCounterChangeSupplyStart !== "") {
							supplyStart = new Date(response.data.customerContract.customerCounterChangeSupplyStart);
						}

						let bio5StartDate = "";
						if (response.data.customerContract.bio5StartDate !== "") {
							bio5StartDate = new Date(response.data.customerContract.bio5StartDate);
						}

						let bio10StartDate = "";
						if (response.data.customerContract.bio10StartDate !== "") {
							bio10StartDate = new Date(response.data.customerContract.bio10StartDate);
						}

						let newTitle = "Abnahmestelle VK " + response.data.customerContract.customerCounterContractAccount + ", letzter Import: " + response.data.customerContract.lastImportDate;

						dispatch({
							type: EDIT_CUSTOMER_CONTRACT,
							payload: {
								id: id,
								title: newTitle,
								isContractEdit: response.data.customerContract.isContractEdit,
								divisionsId: response.data.customerContract.divisionsId,
								customerContractLastImportDate: response.data.customerContract.lastImportDate,
								customerContractProductsId:
									response.data.customerContract.productsId,
								customerContractValidFrom:
									response.data.customerContract.validFrom,
								customerContractValidTo: response.data.customerContract.validTo,
								isCustomerContractTransitionInitialized: response.data.customerContract.isCustomerContractTransitionInitialized,
								isCustomerContractTransition: response.data.customerContract.isCustomerContractTransition,
								customerContractTransitionDate: customerContractTransitionDate,
								isCustomerContractTransitionTenPercent: response.data.customerContract.isCustomerContractTransitionTenPercent,
								customerContractTransitionTenPercentDate: customerContractTransitionTenPercentDate,
								customerContractProfileType: response.data.customerContract.profileType,
								customerContractBillTurnStart:
									response.data.customerContract.billTurnStart,
								customerContractBillTurnEnd:
									response.data.customerContract.billTurnEnd,
								customerContractPotsId: response.data.customerContract.potsId,
								customerContractIsClimatePlus:
									response.data.customerContract.isClimatePlus,
								customerContractIsBio5: response.data.customerContract.isBio5,
								customerContractBio5StartDate: bio5StartDate,
								customerContractIsBio10: response.data.customerContract.isBio10,
								customerContractBio10StartDate: bio10StartDate,
								customerContractIsCo2: response.data.customerContract.isCo2,
								customerContractPeriodConsumption:
									response.data.customerContract.periodConsumption,
								customerContractGrossBillAmount:
									response.data.customerContract.grossBillAmount,
								customerCounterId: response.data.customerContract.id,
								customerCounterNumberCurrent:
									response.data.customerContract.customerCounterNumberCurrent,
								customerCounterNumberDoubling:
									response.data.customerContract.customerCounterNumberDoubling,
								customerCounterNumberFormer:
									response.data.customerContract.customerCounterNumberFormer,
								customerCounterBhkw:
									response.data.customerContract.isCustomerCounterBhkw,
								customerCounterLoadProfileIms: response.data.customerContract.customerCounterLoadProfileIms,
								customerCounterMediumVoltage:
									response.data.customerContract.isCustomerCounterMediumVoltage,
								customerCounterContractAccount:
									response.data.customerContract.customerCounterContractAccount,
								customerCounterContractDescription:
									response.data.customerContract
										.customerCounterContractDescription,
								customerCounterIntendedUse:
									response.data.customerContract.customerCounterIntendedUse,

								customerCounterChangeReasonsId:
									response.data.customerContract.customerCounterChangeReasonsId,
								customerCounterChangeSupplyStart: supplyStart,
								customerCounterFormerSuppliersId:
									response.data.customerContract
										.customerCounterFormerSuppliersId,
								customerCounterInstallmentCycle:
									response.data.customerContract
										.customerCounterInstallmentCycle,
								customerCounterNetworksId:
									response.data.customerContract.customerCounterNetworksId,
								customerCounterLoadProfilesId: response.data.loadProfilesId,
								customerCounterIsNetworkConfirmed:
									response.data.customerContract
										.customerCounterIsNetworkConfirmed,
								customerCounterIsNetworkRequest:
									response.data.customerContract
										.customerCounterIsNetworkRequest,
								customerCounterValue:
									response.data.customerContract.customerCounterValue,
								customerCounterValueNt:
									response.data.customerContract.customerCounterValueNt,
								isCustomerCounterLeaveDone:
									response.data.customerContract.isCustomerCounterLeaveDone,
								customerCounterLocationStreet:
									response.data.customerContract.customerCounterLocationStreet,
								customerCounterLocationHouseNumber:
									response.data.customerContract
										.customerCounterLocationHouseNumber,
								customerCounterLocationPostalCode:
									response.data.customerContract
										.customerCounterLocationPostalCode,
								customerCounterLocationCity:
									response.data.customerContract.customerCounterLocationCity,
								customerCounterLocationDistrict:
									response.data.customerContract
										.customerCounterLocationDistrict,
								customerCounterLocationCountry:
									response.data.customerContract.customerCounterLocationCountry,
								customerCounterLocationAdditional:
									response.data.customerContract
										.customerCounterLocationAdditional,
								customerCounterLocationMarketLocation:
									response.data.customerContract
										.customerCounterLocationMarketLocation,
								customerCounterLocationMeasureLocation:
									response.data.customerContract
										.customerCounterLocationMeasureLocation,
								customerCounterAccountNo:
									response.data.customerContract.customerCounterAccountNo,
								customerCounterAccountOwner:
									response.data.customerContract.customerCounterAccountOwner,
								customerCounterBankCode:
									response.data.customerContract.customerCounterBankCode,
								customerCounterIban:
									response.data.customerContract.customerCounterIban,
								customerCounterContractEnd:
									response.data.customerContract.customerCounterContractEnd,
								customerCounterContractTerminationReasonsId:
									response.data.customerContract
										.customerCounterContractTerminationReasonsId,
								customerContractSalutationsId:
									response.data.customerContract.salutationsId,
								customerContractCustomerTypesId:
									response.data.customerContract.billingCustomerTypesId,
								customerContractBillingMail1: response.data.customerContract.billingMail1,
								customerContractBillingMail2: response.data.customerContract.billingMail2,
								customerContractBillingMail3: response.data.customerContract.billingMail3,
								customerContractBillingName1:
									response.data.customerContract.billingName1,
								customerContractBillingName2:
									response.data.customerContract.billingName2,
								customerContractBillingName3:
									response.data.customerContract.billingName3,
								customerContractBillingStreet:
									response.data.customerContract.billingStreet,
								customerContractBillingHouseNumber:
									response.data.customerContract.billingHouseNumber,
								customerContractBillingPostalCode:
									response.data.customerContract.billingPostalCode,
								customerContractBillingCity:
									response.data.customerContract.billingCity,
								customerContractBillingDistrict:
									response.data.customerContract.billingDistrict,
								customerContractBillingCountry:
									response.data.customerContract.billingCountry,
								customerContractBillingAdditional:
									response.data.customerContract.billingAdditional,
								customerContractBillingPostboxNo:
									response.data.customerContract.billingPostboxNo,
								customerContractBillingPostboxPostalCode:
									response.data.customerContract.billingPostboxPostalCode,
								customerContractBillingPostboxCity:
									response.data.customerContract.billingPostboxCity,

								isPots: response.data.isPots,
								pots: response.data.pots,
								potsid: response.data.potsId,
								isProduct: response.data.isProduct,
								products: response.data.products,
								productsId: response.data.productsId,
								isValidFrom: response.data.isValidFrom,
								validFrom: response.data.validFrom,
								isValidTo: response.data.isValidTo,
								validTo: response.data.validTo,
								isBio5: response.data.isBio5,
								isBio10: response.data.isBio10,
								isCo2: response.data.isCo2,
								isClimatePlus: response.data.isClimatePlus,
								loadProfiles: response.data.loadProfiles,
								loadProfilesId: response.data.loadProfilesId,
								isOptionen: response.data.isOptionen,
							},
						});

						getSupplierAndNetwork(Number.parseInt(response.data.divisionsId));
					} else if (response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log("Anderer Fehler");
					}
				})
				.catch(function (error) {
					if (error.response) {
						// The server answered with an error
						if (error.response.status === 401) {
							window.location.href = "#/logout";
						} else {
							console.log(error.response.data);
							console.log(error.response.status);
							console.log(error.response.headers);
						}
					} else if (error.request) {
						//  The request was made but the server didn't respond
						console.log(error.request);
					} else {
						//  Something else went wrong
						console.log("Error", error.message);
					}
				});
		} else {
			dispatch({
				type: EDIT_CUSTOMER_CONTRACT,
				payload: {
					id: id,
					title: "Neue Abnahmestelle",
					divisionsId: -1,
					isContractEdit: true,
					customerContractProductsId: -1,
					customerContractLastImportDate: '',
					customerContractValidFrom: "Kein Produkt gewählt",
					customerContractValidTo: "Kein Produkt gewählt",
					isCustomerContractTransitionInitialized: 0,
					isCustomerContractInitialTransition: 0,
					isCustomerContractTransition: 0,
					customerContractTransitionDate: '',
					isCustomerContractTransitionTenPercent: 0,
					customerContractTransitionTenPercentDate: '',
					customerContractProfileType: "",
					customerContractBillTurnStart: "",
					customerContractBillTurnEnd: "",
					customerContractPotsId: -1,
					customerContractIsClimatePlus: false,
					customerContractIsBio5: false,
					customerContractBio5StartDate: "",
					customerContractIsBio10: false,
					customerContractBio10StartDate: "",
					customerContractIsCo2: false,
					customerContractPeriodConsumption: "",
					customerContractGrossBillAmount: "",
					customerCounterId: -1,
					customerCounterNumberCurrent: "",
					customerCounterNumberDoubling: 0,
					customerCounterNumberFormer: "",
					customerCounterLoadProfileIms: "",
					customerCounterMediumVoltage: false,
					customerCounterBhkw: false,
					customerCounterContractAccount: "",
					customerCounterContractDescription: "",
					customerCounterIntendedUse: "*",
					customerCounterChangeReasonsId: -1,
					customerCounterChangeSupplyStart: "",
					customerCounterFormerSuppliersId: -1,
					customerCounterNetworksId: -1,
					customerCounterInstallmentCycle: "",
					customerCounterIsNetworkConfirmed: false,
					customerCounterIsNetworkRequest: false,
					customerCounterLoadProfilesId: -1,
					customerCounterValue: "",
					customerCounterValueNt: "",
					isCustomerCounterLeaveDone: false,
					customerCounterLocationStreet: "",
					customerCounterLocationHouseNumber: "",
					customerCounterLocationPostalCode: "",
					customerCounterLocationCity: "",
					customerCounterLocationAdditional: "",
					customerCounterLocationCountry: "DE",
					customerCounterLocationDistrict: "",
					customerCounterLocationMarketLocation: "",
					customerCounterLocationMeasureLocation: "",
					customerCounterAccountNo: "",
					customerCounterAccountOwner: "",
					customerCounterBankCode: "",
					customerCounterIban: "",
					customerCounterContractEnd: "",
					customerCounterContractTerminationReasonsId: -1,
					customerContractSalutationsId: -1,
					customerContractCustomerTypesId: -1,
					customerContractBillingName1: "",
					customerContractBillingName2: "",
					customerContractBillingName3: "",
					customerContractBillingStreet: "",
					customerContractBillingHouseNumber: "",
					customerContractBillingPostalCode: "",
					customerContractBillingCity: "",
					customerContractBillingDistrict: "",
					customerContractBillingCountry: "",
					customerContractBillingAdditional: "",
					customerContractBillingPostboxNo: "",
					customerContractBillingPostboxPostalCode: "",
					customerContractBillingPostboxCity: "",

					isPots: 0,
					pots: [],
					potsid: 0,
					isProduct: 0,
					products: [{ id: -1, value: "Keine Sparte gewählt" }],
					productsId: -1,
					isValidFrom: 0,
					validFrom: "",
					isValidTo: 0,
					validTo: "",
					isBio5: false,
					isBio10: false,
					isCo2: false,
					isClimatePlus: false,
					loadProfiles: [{ id: -1, value: "Keine Sparte gewählt" }],
					loadProfilesId: -1,
					isOptionen: 0,
				},
			});

			getSupplierAndNetwork(-1);
		}
	};

	const getSupplierAndNetwork = (divisionsId) => {
		//	Get suppliers and networks
		let url =
			process.env.REACT_APP_API_URI +
			"/customers/get_customer_contract_foreigns.php";
		let axiosConfig = {
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
		};
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("divisionsId", divisionsId);

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					dispatch({
						type: UPDATE_CONTRACT_EXTERNALS,
						payload: {
							suppliers: response.data.suppliers,
							networks: response.data.networks,
						},
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	GET THE CONTRACTS
	const getContracts = () => {
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("customersId", state.activeCustomerId);

		let url = process.env.REACT_APP_API_URI + "/customers/get_contracts.php";
		let axiosConfig = {
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					const contracts = response.data.contracts;
					for (var i in contracts) {
						contracts[i].id =
							isNaN(contracts[i].id) || contracts[i].id === ""
								? 0
								: Number.parseInt(contracts[i].id);
						contracts[i].postal_code =
							isNaN(contracts[i].postal_code) || contracts[i].postal_code === ""
								? 0
								: Number.parseInt(contracts[i].postal_code);
					}

					dispatch({
						type: GET_CONTRACTS,
						payload: contracts,
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	SAVE THE CONTRACT DETAILS
	const saveContractDetails = () => {

		//	First check if network operator is needed
		const params1st = new URLSearchParams();
		params1st.append('jwt', sessionStorage.jwt);
		params1st.append('transactionReasonsId', state.customerCounterChangeReasonsId);

		let axiosConfig1st = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
		let url1st = process.env.REACT_APP_API_URI + '/leads/check_network_operator.php';

		axios.post(url1st, params1st, axiosConfig1st)
			.then(response => {
				if (response.status === 200) {

					// console.log(response.data);

					if (parseInt(response.data.isNetworkOperatorNeeded) === 1) {

						//	Lieferbeginn gesetzt, Netzbetreiber fehlt
						//	Grund gesetzt, Netzbetreiber fehlt
						if ((state.customerCounterChangeSupplyStart !== "" || state.customerCounterChangeReasonsId > 0) && state.customerCounterNetworksId < 0) {

							setContractErrMsg("Bitte wählen Sie einen Netzbetreiber für den Lieferantenwechsel aus", 2);
							return false;
						}

						//	Grund gesetzt, Lieferbeginn fehlt
						//	Netzbetreiber gesetzt, Lieferbeginn fehlt
						if ((state.customerCounterChangeReasonsId > 0 || state.customerCounterNetworksId > 0) && state.customerCounterChangeSupplyStart === "") {

							setContractErrMsg("Bitte geben Sie einen Lieferbeginn beim Lieferantenwechsel ein.", 2);
							return false;
						}

					} else {

						//	Lieferbeginn fehlt
						if (state.customerCounterChangeReasonsId > 0 && state.customerCounterChangeSupplyStart === "") {

							setContractErrMsg("Bitte geben Sie einen Lieferbeginn beim Lieferantenwechsel ein.", 2);
							return false;
						}

						//	Grund fehlt
						if (state.customerCounterChangeSupplyStart !== "" && state.customerCounterChangeReasonsId < 0) {

							setContractErrMsg("Bitte wählen Sie einen Grund für den Lieferantenwechsel aus.", 2);
							return false;
						}
					}

					if ((state.customerCounterChangeReasonsId > 0 && state.customerCounterChangeSupplyStart === "") ||
						(state.customerCounterChangeReasonsId < 0 && state.customerCounterChangeSupplyStart !== "")) {

						setContractErrMsg("Die Angaben für den Lieferantenwechsel sind nicht vollständig. Bitte korrigieren Sie die Eingaben.", 2);
						return false;
					}

					if (state.customerContractDivisionsId <= 0) {
						setContractErrMsg("Bitte wählen Sie eine Sparte für die Abnahmestelle aus.", 1);
						return false;
					}

					if (state.customerContractPotsId === 0) {
						setContractErrMsg("Bitte wählen Sie einen Energietopf/ein Produkt für die Abnahmestelle aus.", 1);
						return false;
					}

					if (state.isCustomerContractTransition === 1 && state.customerContractTransitionDate === '') {
						setContractErrMsg("Bitte geben Sie ein Datum für den Kundenübergang an", 1);
						return false;
					}

					if (state.isCustomerContractTransitionTenPercent === 1 && state.customerContractTransitionTenPercentDate === '') {
						setContractErrMsg("Bitte geben Sie ein Datum für den 10%-Kundenübergang an.", 1);
						return false;
					}

					const params = new URLSearchParams();
					params.append("jwt", sessionStorage.jwt);
					params.append("id", state.activeCustomerContract);
					params.append("table", "customer_contracts");
					params.append("customers_id", state.activeCustomerId);
					params.append("divisions_id", state.customerContractDivisionsId);
					params.append("is_bio5", state.customerContractIsBio5);
					params.append("is_bio10", state.customerContractIsBio10);
					params.append("is_climate_plus", state.customerContractIsClimatePlus);
					params.append("is_co2", state.customerContractIsCo2);

					let periodConsumption = state.customerContractPeriodConsumption.replace(".", "");
					params.append("period_consumption", periodConsumption);

					params.append("gross_bill_amount", state.customerContractGrossBillAmount);
					params.append("pots_id", state.customerContractPotsId);
					params.append("products_id", state.customerContractProductsId);
					params.append(
						"valid_from",
						state.customerContractValidFrom === "Kein Produkt gewählt"
							? ""
							: state.customerContractValidFrom
					);
					params.append(
						"valid_to",
						state.customerContractValidTo === "Kein Produkt gewählt"
							? ""
							: state.customerContractValidTo
					);
					const isCCIT = (state.isCustomerContractTransitionInitialized > 0) ? 2 : 0;
					params.append("is_customer_contract_transition_initialized", isCCIT);
					const isCCT = (state.isCustomerContractTransition === 1 || state.isCustomerContractTransition === true) ? true : false;
					params.append("is_customer_contract_transition", isCCT);
					const isCCTTP = (state.isCustomerContractTransitionTenPercent === 1 || state.isCustomerContractTransitionTenPercent === true) ? true : false;
					params.append("is_customer_contract_transition_ten_percent", isCCTTP);
					params.append("profile_type", state.customerContractProfileType);
					params.append(
						"customer_counter_number_current",
						state.customerCounterNumberCurrent
					);
					params.append(
						"customer_counter_number_former",
						state.customerCounterNumberFormer
					);
					params.append(
						"is_customer_counter_medium_voltage",
						state.customerCounterMediumVoltage
					);
					params.append("is_customer_counter_bhkw", state.customerCounterBhkw);
					params.append(
						"customer_counter_contract_account",
						state.customerCounterContractAccount
					);
					params.append(
						"customer_counter_contract_description",
						state.customerCounterContractAccountDescription
					);
					params.append(
						"customer_counter_intended_use",
						state.customerCounterIntendedUse
					);
					params.append(
						"customer_counter_change_reasons_id",
						state.customerCounterChangeReasonsId
					);
					params.append(
						"customer_counter_is_network_request",
						state.customerCounterIsNetworkRequest
					);
					params.append(
						"customer_counter_former_suppliers_id",
						state.customerCounterFormerSupplierId
					);
					params.append(
						"customer_counter_networks_id",
						state.customerCounterNetworksId
					);
					params.append(
						"customer_counter_installment_cycle",
						state.customerCounterInstallmentCycle
					);
					params.append(
						"customer_counter_load_profiles_id",
						state.customerCounterLoadProfilesId
					);
					params.append("customer_counter_value", state.customerCounterValue);
					params.append("customer_counter_value_nt", state.customerCounterValueNt);
					params.append(
						"is_customer_counter_leave_done",
						state.isCustomerCounterLeaveDone
					);

					params.append(
						"customer_counter_location_street",
						state.customerCounterLocationStreet
					);
					params.append(
						"customer_counter_location_house_number",
						state.customerCounterLocationHouseNumber
					);
					params.append(
						"customer_counter_location_postal_code",
						state.customerCounterLocationPostalCode
					);
					params.append(
						"customer_counter_location_city",
						state.customerCounterLocationCity
					);
					params.append(
						"customer_counter_location_district",
						state.customerCounterLocationDistrict
					);
					params.append(
						"customer_counter_location_country",
						state.customerCounterLocationCountry
					);
					params.append(
						"customer_counter_location_additional",
						state.customerCounterLocationAdditional
					);
					params.append(
						"customer_counter_location_market_location",
						state.customerCounterLocationMarketLocation
					);
					params.append(
						"customer_counter_location_measure_location",
						state.customerCounterLocationMeasureLocation
					);
					params.append(
						"customer_counter_account_no",
						state.customerCounterAccountNo
					);
					params.append(
						"customer_counter_account_owner",
						state.customerCounterAccountOwner
					);
					params.append("customer_counter_bank_code", state.customerCounterBankCode);
					params.append("customer_counter_iban", state.customerCounterIban);
					params.append(
						"customer_counter_contract_end",
						state.customerCounterContractEnd
					);
					params.append(
						"customer_counter_contract_termination_reasons_id",
						state.customerCounterContractTerminationReasonsId
					);

					params.append("billing_name1", state.customerContractBillingName1);
					params.append("billing_name2", state.customerContractBillingName2);
					params.append("billing_name3", state.customerContractBillingName3);
					params.append(
						"billing_customer_types_id",
						state.customerContractCustomerTypesId
					);
					params.append("salutations_id", state.customerContractSalutationsId);
					params.append("billing_street", state.customerContractBillingStreet);
					params.append(
						"billing_house_number",
						state.customerContractBillingHouseNumber
					);
					params.append(
						"billing_postal_code",
						state.customerContractBillingPostalCode
					);
					params.append("billing_city", state.customerContractBillingCity);
					params.append("billing_district", state.customerContractBillingDistrict);
					params.append("billing_country", state.customerContractBillingCountry);
					params.append(
						"billing_additional",
						state.customerContractBillingAdditional
					);
					params.append("billing_postbox_no", state.customerContractBillingPostboxNo);
					params.append(
						"billing_postbox_postal_code",
						state.customerContractBillingPostboxPostalCode
					);
					params.append(
						"billing_postbox_city",
						state.customerContractBillingPostboxCity
					);

					const customerContractTransitionDate = state.customerContractTransitionDate !== "" && state.customerContractTransitionDate !== null ? new Date(state.customerContractTransitionDate) : "";
					let newCustomerContractTransitionDate = '';
					if (customerContractTransitionDate !== "") {
						const newCTDay = customerContractTransitionDate.getDate();
						const newCTMonth = customerContractTransitionDate.getMonth() + 1;
						const newCTYear = customerContractTransitionDate.getFullYear();
						newCustomerContractTransitionDate = newCTYear + '-' + newCTMonth + '-' + newCTDay;
					}
					params.append("customer_contract_transition_date", newCustomerContractTransitionDate);

					const customerContractTransitionTenPercentDate =
						state.customerContractTransitionTenPercentDate !== "" && state.customerContractTransitionTenPercentDate !== null ? new Date(state.customerContractTransitionTenPercentDate) : "";
					let newCustomerContractTransitionTenPercentDate = '';
					if (customerContractTransitionTenPercentDate !== "") {
						const newCTDay = customerContractTransitionTenPercentDate.getDate();
						const newCTMonth = customerContractTransitionTenPercentDate.getMonth() + 1;
						const newCTYear = customerContractTransitionTenPercentDate.getFullYear();
						newCustomerContractTransitionTenPercentDate = newCTYear + '-' + newCTMonth + '-' + newCTDay;
					}
					params.append("customer_contract_transition_ten_percent_date", newCustomerContractTransitionTenPercentDate);

					const customerCounterChangeSupplyStart =
						state.customerCounterChangeSupplyStart !== ""
							? new Date(state.customerCounterChangeSupplyStart)
							: "";
					let newCustomerCounterChangeSupplyStart = "";
					if (customerCounterChangeSupplyStart !== "") {
						const newDay = customerCounterChangeSupplyStart.getDate();
						const newMonth = customerCounterChangeSupplyStart.getMonth() + 1;
						const newYear = customerCounterChangeSupplyStart.getFullYear();
						newCustomerCounterChangeSupplyStart =
							newYear + "-" + newMonth + "-" + newDay;
					}
					params.append(
						"customer_counter_change_supply_start",
						newCustomerCounterChangeSupplyStart
					);

					const billTurnStart =
						state.customerContractBillTurnStart !== ""
							? new Date(state.customerContractBillTurnStart)
							: "";
					let newBillTurnStart = "";
					if (billTurnStart !== "") {
						const newDay = billTurnStart.getDate();
						const newMonth = billTurnStart.getMonth() + 1;
						const newYear = billTurnStart.getFullYear();
						newBillTurnStart = newYear + "-" + newMonth + "-" + newDay;
					}
					params.append("bill_turn_start", newBillTurnStart);

					const billTurnEnd =
						state.customerContractBillTurnEnd !== ""
							? new Date(state.customerContractBillTurnEnd)
							: "";
					let newBillTurnEnd = "";
					if (billTurnEnd !== "") {
						const newDay = billTurnEnd.getDate();
						const newMonth = billTurnEnd.getMonth() + 1;
						const newYear = billTurnEnd.getFullYear();
						newBillTurnEnd = newYear + "-" + newMonth + "-" + newDay;
					}
					params.append("bill_turn_end", newBillTurnEnd);

					const customerContractBio5StartDate =
						state.customerContractBio5StartDate !== ""
							? new Date(state.customerContractBio5StartDate)
							: "";
					let newCustomerContractBio5StartDate = "";
					if (customerContractBio5StartDate !== "") {
						const newBio5Day = customerContractBio5StartDate.getDate();
						const newBio5Month = customerContractBio5StartDate.getMonth() + 1;
						const newBio5Year = customerContractBio5StartDate.getFullYear();
						newCustomerContractBio5StartDate =
							newBio5Year + "-" + newBio5Month + "-" + newBio5Day;
					}
					params.append("bio5_start_date", newCustomerContractBio5StartDate);

					const customerContractBio10StartDate =
						state.customerContractBio10StartDate !== ""
							? new Date(state.customerContractBio10StartDate)
							: "";
					let newCustomerContractBio10StartDate = "";
					if (customerContractBio10StartDate !== "") {
						const newBio10Day = customerContractBio10StartDate.getDate();
						const newBio10Month = customerContractBio10StartDate.getMonth() + 1;
						const newBio10Year = customerContractBio10StartDate.getFullYear();
						newCustomerContractBio10StartDate =
							newBio10Year + "-" + newBio10Month + "-" + newBio10Day;
					}
					params.append("bio10_start_date", newCustomerContractBio10StartDate);

					let axiosConfig = {
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
						},
					};

					let url =
						process.env.REACT_APP_API_URI + "/customers/set_customer_contract.php";

					axios
						.post(url, params, axiosConfig)
						.then((response) => {
							if (response.status === 200) {
								// console.log(response.data);

								closeContractsDialog();
								getContracts();
							} else if (response.status === 401) {
								window.location.href = "#/logout";
							} else {
								console.log("Anderer Fehler");
							}
						})
						.catch(function (error) {
							if (error.response) {
								// The server answered with an error
								if (error.response.status === 401) {
									window.location.href = "#/logout";
								} else {
									console.log(error.response.data);
									console.log(error.response.status);
									console.log(error.response.headers);
								}
							} else if (error.request) {
								//  The request was made but the server didn't respond
								console.log(error.request);
							} else {
								//  Something else went wrong
								console.log("Error", error.message);
							}
						});


				} else if (response.status === 401) {
					window.location.href = '#/logout';
				} else {
					console.log('Anderer Fehler');
					return false;
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = '#/logout';
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
						return false;
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
					return false;
				} else {
					//  Something else went wrong
					console.log('Error', error.message);
					return false;
				}
			})
	};

	//	SET ACTIVE CONTRACT TAB
	const setActiveContractTab = (tabIndex) => {
		dispatch({
			type: SET_ACTIVE_CONTRACT_TAB,
			payload: tabIndex,
		});
	};

	//------------------------------------
	//	CUSTOMER CONTRACTS CONTRACT
	//------------------------------------

	//	CHANGE THE DIVISION OF CONTRACT
	const customerContractDivisionsIdChange = (e) => {

		if (state.customerContractsDialogError !== '' && state.customerContractDivisionsId <= 0)
			setContractErrMsg("", 1);

		const divisionsId = e.target.value;

		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("divisionsId", divisionsId);
		params.append("energyCampaignsId", state.activeEnergyCampaignsId);
		params.append("energyPotsId", state.energyPotsId);
		params.append("gasCampaignsId", state.activeGasCampaignsId);
		params.append("gasPotsId", state.gasPotsId);
		params.append("customerId", state.activeCustomerId);
		params.append("activeCustomerContract", state.activeCustomerContract);

		let url =
			process.env.REACT_APP_API_URI + "/customers/get_contract_product.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					//   console.log(response.data);

					dispatch({
						type: CUSTOMER_CONTRACT_DIVISIONS_ID_CHANGE,
						payload: {
							divisionsId: divisionsId,
							isPots: response.data.isPots,
							pots: response.data.pots,
							potsId: response.data.potsId,
							isProduct: response.data.isProduct,
							products: response.data.products,
							productsId: response.data.productsId,
							isValidFrom: response.data.isValidFrom,
							validFrom: response.data.validFrom,
							isValidTo: response.data.isValidTo,
							validTo: response.data.validTo,
							isBio5: response.data.isBio5,
							bio5StartDate: response.data.bio5StartDate,
							isBio10: response.data.isBio10,
							bio10StartDate: response.data.bio10StartDate,
							isCo2: response.data.isCo2,
							isClimatePlus: response.data.isClimatePlus,
							loadProfiles: response.data.loadProfiles,
							loadProfilesId: response.data.loadProfilesId,
							isOptionen: response.data.isOptionen,
							suppliers: response.data.suppliers,
							networks: response.data.networks,
						},
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	CHANGE THE GROSS BILL AMOUNT
	const customerContractGrossBillAmountChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_GROSS_BILL_AMOUNT_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE BIO10 OPTION
	const customerContractIsBio10Change = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_IS_BIO10_CHANGE,
			payload: e.target.value,
		});
	};

	const customerContractBio10StartDateChange = (date) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BIO10_START_DATE_CHANGE,
			payload: date,
		});
	};

	//	CHANGE BIO5 OPTION
	const customerContractIsBio5Change = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_IS_BIO5_CHANGE,
			payload: e.target.value,
		});
	};

	const customerContractBio5StartDateChange = (date) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BIO5_START_DATE_CHANGE,
			payload: date,
		});
	};

	//	CHANGE CLIMATE PLUS OPTION
	const customerContractIsClimatePlusChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_IS_CLIMATE_PLUS_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT PERIOD CONSUMPTION
	const customerContractPeriodConsumptionChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_PERIOD_CONSUMPTION_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT POTS ID
	const customerContractPotsIdChange = (e) => {

		if (state.customerContractsDialogError !== "" && state.customerContractPotsId === 0)
			setContractErrMsg("", 1);

		const potsId = e.target.value;

		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("divisionsId", state.customerContractDivisionsId);
		params.append("energyCampaignsId", state.activeEnergyCampaignsId);
		params.append("gasCampaignsId", state.activeGasCampaignsId);
		params.append("activeCustomerContract", state.activeCustomerContract);

		if (state.customerContractDivisionsId === "1") {
			params.append("energyPotsId", state.energyPotsId);
			params.append("gasPotsId", potsId);
		} else {
			params.append("energyPotsId", potsId);
			params.append("gasPotsId", state.gasPotsId);
		}
		params.append("customerId", state.activeCustomerId);

		let url =
			process.env.REACT_APP_API_URI + "/customers/get_contract_product.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					//   console.log(response.data);

					let isCustomerContractTransitionInitialized = parseInt(state.isCustomerContractTransitionInitialized < 2) ? 1 : state.isCustomerContractTransitionInitialized;
					let isCustomerContractInitialTransition = (parseInt(state.isCustomerContractTransitionInitialized) < 2) ? 1 : state.isCustomerContractInitialTransition;
					let isCustomerContractTransition = (isCustomerContractInitialTransition === 1) ? 1 : state.isCustomerContractTransition;
					let customerContractTransitionDate = state.customerContractTransitionDate;
					let isCustomerContractTransitionTenPercent = (isCustomerContractInitialTransition === 1) ? 1 : state.isCustomerContractTransitionTenPercent;
					let customerContractTransitionTenPercentDate = state.customerContractTransitionTenPercentDate;

					if (state.activeCustomerContract <= 0) {

						isCustomerContractTransitionInitialized = parseInt(response.data.isCustomerContractTransitionInitialized) < 2 ? 1 : parseInt(response.data.isCustomerContractTransitionInitialized);
						isCustomerContractInitialTransition = parseInt(response.data.isCustomerContractTransitionInitialized) < 2 ? 1 : state.isCustomerContractInitialTransition;
						isCustomerContractTransition = (isCustomerContractInitialTransition === 1) ? 1 : response.data.isCustomerContractTransition;
						if (response.data.customerContractTransitionDate !== "") {
							customerContractTransitionDate = new Date(response.data.customerContractTransitionDate);
						}

						isCustomerContractTransitionTenPercent = (isCustomerContractInitialTransition === 1) ? 1 : response.data.isCustomerContractTransitionTenPercent;
						if (response.data.customerContractTransitionTenPercentDate !== "") {
							customerContractTransitionTenPercentDate = new Date(response.data.customerContractTransitionTenPercentDate);
						}
					}

					dispatch({
						type: CUSTOMER_CONTRACT_POTS_ID_CHANGE,
						payload: {
							potsId: potsId,
							isCustomerContractTransitionInitialized: isCustomerContractTransitionInitialized,
							isCustomerContractInitialTransition,
							isCustomerContractTransition: isCustomerContractTransition,
							customerContractTransitionDate: customerContractTransitionDate,
							isCustomerContractTransitionTenPercent: isCustomerContractTransitionTenPercent,
							customerContractTransitionTenPercentDate: customerContractTransitionTenPercentDate,
						}
					});

					dispatch({
						type: CUSTOMER_CONTRACT_DIVISIONS_ID_CHANGE,
						payload: {
							divisionsId: state.customerContractDivisionsId,
							isPots: 1,
							pots: state.pots,
							potsId: potsId,
							isProduct: response.data.isProduct,
							products: response.data.products,
							productsId: response.data.productsId,
							isValidFrom: response.data.isValidFrom,
							validFrom: response.data.validFrom,
							isValidTo: response.data.isValidTo,
							validTo: response.data.validTo,
							isBio5: response.data.isBio5,
							bio5StartDate: response.data.bio5StartDate,
							isBio10: response.data.isBio10,
							bio10StartDate: response.data.bio10StartDate,
							isCo2: response.data.isCo2,
							isClimatePlus: response.data.isClimatePlus,
							loadProfiles: response.data.loadProfiles,
							loadProfilesId: response.data.loadProfilesId,
							isOptionen: response.data.isOptionen,
							suppliers: response.data.suppliers,
							networks: response.data.networks,
						},
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	CHANGE THE PRODUCT ID
	const customerContractProductsIdChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_PRODUCTS_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE NETWORK PROFILE TYPE
	const customerContractProfileTypeChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_PROFILE_TYPE_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT VALID FROM DATE
	const customerContractValidFromChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_VALID_FROM_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT VALID TO DATE
	const customerContractValidToChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_VALID_TO_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE THE CONTRACT TRANSITION CHECKBOX
	const isCustomerContractTransitionChange = (e) => {
		const isChange = (e.target.checked === false) ? 0 : 1;
		dispatch({
			type: IS_CUSTOMER_CONTRACT_TRANSITION_CHANGE,
			payload: isChange,
		});
	}

	//	CHANGE THE CONTRACT TRANSITION DATE
	const customerContractTransitionDateChange = (date) => {
		dispatch({
			type: CUSTOMER_CONTRACT_TRANSITION_DATE_CHANGE,
			payload: date,
		});
	}

	//	CHANGE THE CONTRACT 10% TRANSITION CHECKBOX
	const isCustomerContractTransitionTenPercentChange = (e) => {
		const isChange = (e.target.checked === false) ? 0 : 1;
		dispatch({
			type: IS_CUSTOMER_CONTRACT_TRANSITION_TEN_PERCENT_CHANGE,
			payload: isChange,
		});
	}

	//CHANGE THE CONTRACT 10% TRANSITION DATE
	const customerContractTransitionTenPercentDateChange = (date) => {
		dispatch({
			type: CUSTOMER_CONTRACT_TRANSITION_TEN_PERCENT_DATE_CHANGE,
			payload: date,
		});
	}

	//-----------------------------------------
	//	CUSTOMER CONTRACT COUNTER
	//-----------------------------------------

	//	CHANGE CONTRACT BHKW FLAG
	const customerCounterBhkwChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_BHKW_CHANGE,
			payload: e.target.checked,
		});
	};

	//	CHANGE CONTRACT ACCOUNT
	const customerCounterContractAccountChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_CONTRACT_ACCOUNT_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT ACCOUNT DESCRIPTION
	const customerCounterContractAccountDescriptionChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_CONTRACT_ACCOUNT_DESCRIPTION_CHANGE,
			payload: e.target.value.substring(0, 35),
		});
	};

	//	CHANGE CONTRACT INTENDED USE
	const customerCounterIntendedUseChange = (e) => {

		let intendedUse = e.target.value === '' ? '*' : e.target.value.substring(0, 27)
		dispatch({
			type: CUSTOMER_COUNTER_INTENDED_USE_CHANGE,
			payload: intendedUse,
		});
	};

	//	CHANGE MEDIUM VOLTAGE FLAG
	const customerCounterMediumVoltageChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_MEDIUM_VOLTAGE_CHANGE,
			payload: e.target.checked,
		});
	};

	//	CHANGE CURRENT CUSTOMER NUMBER
	const customerCounterNumberCurrentChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_NUMBER_CURRENT_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE FORMER CUSTOMER NUMBER
	const customerCounterNumberFormerChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_NUMBER_FORMER_CHANGE,
			payload: e.target.value,
		});
	};

	//	SET THE ACTIVE COUNTER TAB
	const setActiveContractCounterTab = (tabIndex) => {
		dispatch({
			type: SET_ACTIVE_CONTRACT_COUNTER_TAB,
			payload: tabIndex,
		});
	};

	//---------------------------------------
	//	CUSTOMER CONTRACT COUNTER CHANGE
	//---------------------------------------

	//	CHANGE CHANGE SUPPLY REASON
	const customerCounterChangeReasonsIdChange = (e) => {

		if (state.customerContractsDialogError !== "")
			setContractErrMsg("", 2);

		dispatch({
			type: CUSTOMER_COUNTER_CHANGE_REASONS_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE SUPPLY START
	const customerCounterChangeSupplyStartChange = (date) => {

		if (state.customerContractsDialogError !== "")
			setContractErrMsg("", 2);

		if (!date)
			date = "";
		dispatch({
			type: CUSTOMER_COUNTER_CHANGE_SUPPLY_START_CHANGE,
			payload: date,
		});
	};

	//	CHANGE FORMER SUPPLIER OF CONTRACT
	const customerCounterFormerSupplierIdChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_FORMER_SUPPLIER_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE INSTALLMENT CYCLE
	const customerCounterInstallmentCycleChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_INSTALLMENT_CYCLE_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE LOAD PROFILE OF CONTRACT
	const customerCounterLoadProfilesIdChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_LOAD_PROFILES_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE NETWORK OF CONTRACT
	const customerCounterNetworksIdChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_NETWORKS_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//---------------------------------------
	//	CUSTOMER CONTRACT COUNTER LOCATION
	//---------------------------------------

	//	CHANGE CONTRACT ADDRESS ADDITION
	const customerCounterLocationAdditionalChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_LOCATION_ADDITIONAL_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT CITY
	const customerCounterLocationCityChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_LOCATION_CITY_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT COUNTRY
	const customerCounterLocationCountryChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_LOCATION_COUNTRY_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT DISTRICT
	const customerCounterLocationDistrictChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_LOCATION_DISTRICT_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT HOUSE NUMBER
	const customerCounterLocationHouseNumberChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_LOCATION_HOUSE_NUMBER_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT MARKET LOCATION
	const customerCounterLocationMarketLocationChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_LOCATION_MARKET_LOCATION_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT MEASURE LOCATION
	const customerCounterLocationMeasureLocationChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_LOCATION_MEASURE_LOCATION_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT POSTAL CODE
	const customerCounterLocationPostalCodeChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_LOCATION_POSTAL_CODE_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT STREET
	const customerCounterLocationStreetChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_LOCATION_STREET_CHANGE,
			payload: e.target.value,
		});
	};

	//-------------------------------------------
	//	CUSTOMER CONTRACT COUNTER BANK
	//-------------------------------------------

	//	CHANGE THE CONTRACT ACCOUNT NUMBER
	const customerCounterAccountNoChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_ACCOUNT_NO_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE THE CONTRACT ACCOUNT OWNER
	const customerCounterAccountOwnerChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_ACCOUNT_OWNER_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT BANK CODE
	const customerCounterBankCodeChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_BANK_CODE_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT IBAN
	const customerCounterIbanChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_IBAN_CHANGE,
			payload: e.target.value,
		});
	};

	//-----------------------------------------
	//	CUSTOMER CONTRACT COUNTER END
	//-----------------------------------------

	//	CHANGE THE CONTRACT END DATE
	const customerCounterContractEndChange = (date) => {

		let newDate = '';
		if (date) {
			const year = date.getYear() + 1900;
			const month = date.getMonth() + 1;
			const day = date.getDate();
			newDate = year + "-" + month + "-" + day;
		}

		dispatch({
			type: CUSTOMER_COUNTER_CONTRACT_END,
			payload: newDate,
		});
	};

	//	CHANGE CONTRACT END REASONG
	const customerCounterContractTerminationReasonsIdChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_CONTRACT_TERMINATION_REASONS_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE COUNTER VALUE LEAVE
	const customerCounterValueChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_VALUE_CHANGE,
			payload: e.target.value,
		});
	};

	const customerCounterValueNtChange = (e) => {
		dispatch({
			type: CUSTOMER_COUNTER_VALUE_NT_CHANGE,
			payload: e.target.value,
		});
	};

	const isCustomerCounterLeaveDoneChange = (e) => {
		dispatch({
			type: IS_CUSTOMER_COUNTER_LEAVE_DONE_CHANGE,
			payload: e.target.checked,
		});
	};

	//------------------------------------------
	//	CUSTOMER CONTRACT BILLING
	//------------------------------------------

	//	CHANGE CONTRACT BILLING ADDRESS ADDITION
	const customerContractBillingAdditionalChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BILLING_ADDITIONAL_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT BILLING CITY
	const customerContractBillingCityChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BILLING_CITY_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT BILLING COUNTRY
	const customerContractBillingCountryChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BILLING_COUNTRY_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT BILLING DISTRICT
	const customerContractBillingDistrictChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BILLING_DISTRICT_CHANGE,
			payload: e.target.value,
		});
	};

	// 	CHANGE CONTRACT BILLING HOUSE NUMBER
	const customerContractBillingHouseNumberChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BILLING_HOUSE_NUMBER_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT BILLING NAME 1
	const customerContractBillingName1Change = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BILLING_NAME1_CHANGE,
			payload: e.target.value.substring(0, 40),
		});
	};

	//	CHANGE CONTRACT BILLING NAME 2
	const customerContractBillingName2Change = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BILLING_NAME2_CHANGE,
			payload: e.target.value.substring(0, 40),
		});
	};

	//	CHANGE CONTRACT BILLING NAME 3
	const customerContractBillingName3Change = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BILLING_NAME3_CHANGE,
			payload: e.target.value.substring(0, 40),
		});
	};

	//	CHANGE CONTRACT BILLING POSTAL CODE
	const customerContractBillingPostalCodeChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BILLING_POSTAL_CODE_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT BILLING POSTBOX CITY
	const customerContractBillingPostboxCityChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BILLING_POSTBOX_CITY_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT BILLING POSTBOX NUMBER
	const customerContractBillingPostboxNoChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BILLING_POSTBOX_NO_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT BILLING POSTBOX POSTAL CODE
	const customerContractBillingPostboxPostalCodeChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BILLING_POSTBOX_POSTAL_CODE_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT BILLING STREET
	const customerContractBillingStreetChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_BILLING_STREET_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT BILLING SALUTATION
	const customerContractSalutationsIdChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_SALUTATIONS_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTRACT BILLING CUSTOMER TYPE
	const customerContractCustomerTypesIdChange = (e) => {
		dispatch({
			type: CUSTOMER_CONTRACT_CUSTOMER_TYPES_ID_CHANGE,
			payload: e.target.value,
		});
	};

	//-----------------------------------------
	//	CUSTOMER CONTACT HISTORY
	//-----------------------------------------

	//	CHANGE CONTACT HISTORY OBJECT
	const changeContactHistoryDetailObjectId = (e) => {
		dispatch({
			type: CHANGE_CONTACT_HISTORY_DETAIL_OBJECT_ID,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTACT HISTORY REMARK
	const changeContactHistoryDetailRemarks = (e) => {
		//  Check for error message clearance
		if (
			state.contactHistoryError !== "" &&
			state.contactHistoryDetailRemarks === ""
		)
			setContactHistoryError("");

		dispatch({
			type: CHANGE_CONTACT_HISTORY_DETAIL_REMARKS,
			payload: e.target.value,
		});
	};

	//	CHANGE CONTACT HISTORY TOPIC
	const changeContactHistoryDetailTopic = (e) => {
		//  Check for clearance of error message
		if (
			state.contactHistoryError !== "" &&
			state.contactHistoryDetailTopic === ""
		)
			setContactHistoryError("");

		dispatch({
			type: CHANGE_CONTACT_HISTORY_DETAIL_TOPIC,
			payload: e.target.value,
		});
	};

	//	CONFIRM CONTACT HISTORY ITEM DELETE
	const confirmDeleteContactHistory = (id) => {
		const entityName =
			"Möchten Sie den Kontakt-Eintrag #" + id + " endgültig löschen?";

		dispatch({
			type: DELETE_LEAD_CONTACT_HISTORY_CONFIRM,
			payload: {
				entityName: entityName,
				id: id,
			},
		});
	};

	//	CLOSE THE CONTACT HISTORY DETAIL DIALOG
	const closeContactHistoryDialog = () => {
		dispatch({
			type: CLOSE_CONTACT_HISTORY_DIALOG,
		});
	};

	//	CLOSE CONTACT HISTORY DELETE CONFIRM DIALOG
	const closeCustomerContactHistoryConfirm = () => {
		dispatch({
			type: CLOSE_DELETE_CUSTOMER_CONTACT_HISTORY_CONFIRM,
		});
	};

	//	CHANGE THE CONTACT HISTORY UPLOAD DOCUMENT
	const contactHistoryDetailDocumentChange = (e) => {
		//  File Upload
		const fd = new FormData();
		fd.append("image", e.target.files[0], e.target.files[0].name);
		fd.append("jwt", sessionStorage.jwt);

		let url =
			process.env.REACT_APP_API_URI + "/upload/upload_form_document.php";

		axios
			.post(url, fd, {
				headers: {
					"content-type": "multipart/form-data",
				},
			})
			.then((res) => {
				// console.log(res.data);

				dispatch({
					type: CONTACT_HISTORY_DETAIL_DOCUMENT_CHANGE,
					payload: res.data,
				});
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	DELETE THE CONTACT HISTORY UPLOAD DOCUMENT
	const contactHistoryDetailDocumentDelete = () => {
		dispatch({
			type: CONTACT_HISTORY_DETAIL_DOCUMENT_DELETE,
		});
	};

	//	DELETE THE CONTACT HISTORY ENTRY
	const deleteCustomerContactHistory = () => {
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", state.activeContactHistory);

		let url =
			process.env.REACT_APP_API_URI +
			"/customers/delete_customer_contact_history.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);
					closeCustomerContactHistoryConfirm();
					getContactHistories();
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	EDIT A CONTACT HISTORY ENTRY
	const editContactHistory = (id, topic = '') => {
		if (id > 0) {
			const params = new URLSearchParams();
			params.append("jwt", sessionStorage.jwt);
			params.append("id", id);

			let url =
				process.env.REACT_APP_API_URI +
				"/customers/get_customer_contact_history.php";
			let axiosConfig = {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			};


			axios
				.post(url, params, axiosConfig)
				.then((response) => {
					if (response.status === 200) {
						// console.log(response.data);

						dispatch({
							type: EDIT_CONTACT_HISTORY,
							payload: {
								id: id,
								title: 'Kontakt-Eintrag bearbeiten',
								topic: response.data.customerContactHistory.topic,
								remarks: response.data.customerContactHistory.remarks,
								objectId: response.data.customerContactHistory.objectId,
								document: response.data.customerContactHistory.document,
								isUser: response.data.customerContactHistory.isUser,
							},
						});
					} else if (response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log("Anderer Fehler");
					}
				})
				.catch(function (error) {
					if (error.response) {
						// The server answered with an error
						if (error.response.status === 401) {
							window.location.href = "#/logout";
						} else {
							console.log(error.response.data);
							console.log(error.response.status);
							console.log(error.response.headers);
						}
					} else if (error.request) {
						//  The request was made but the server didn't respond
						console.log(error.request);
					} else {
						//  Something else went wrong
						console.log("Error", error.message);
					}
				});
		} else {
			dispatch({
				type: EDIT_CONTACT_HISTORY,
				payload: {
					id: id,
					title: 'Neuer Kontakt-Eintrag',
					topic: topic,
					remarks: "",
					objectId: -1,
					document: "",
					isUser: "1",
				},
			});
		}
	};

	//	GET THE CONTACT HISTORY ENTRIES FOR THE CUSTOMER
	const getContactHistories = () => {
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("customerId", state.activeCustomerId);

		let url =
			process.env.REACT_APP_API_URI +
			"/customers/get_customer_contact_histories.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					dispatch({
						type: GET_CONTACT_HISTORIES,
						payload: response.data.customerContactHistories,
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	SAVE THE CONTACT HISTORY DETAIL DIALOG
	const saveContactHistoryDialog = () => {
		//  Check for mandatory fields
		if (state.contactHistoryDetailTopic === "")
			return setContactHistoryError("Bitte geben Sie ein Thema an!");

		if (state.contactHistoryDetailRemarks === "")
			return setContactHistoryError("Bitte geben Sie eine Bemerkung ein!");

		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", state.activeContactHistory);
		params.append("customersId", state.activeCustomerId);
		params.append("topic", state.contactHistoryDetailTopic);
		params.append("remarks", state.contactHistoryDetailRemarks);
		params.append("objectId", state.contactHistoryDetailObjectId);
		params.append("document", state.contactHistoryDetailDocument);
		params.append("isUser", 1);

		let url =
			process.env.REACT_APP_API_URI + "/customers/set_contact_history.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					dispatch({
						type: CLOSE_CONTACT_HISTORY_DIALOG,
					});

					getContactHistories();
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	SET THE ERROR MESSAGE IN THE CONTACT HISTORY DETAIL DIALOG
	const setContactHistoryError = (errMsg) => {
		dispatch({
			type: SET_CONTACT_HISTORY_ERROR,
			payload: errMsg,
		});
	};

	//--------------------------------------
	//	CUSTOMER DELETE
	//--------------------------------------

	const closeCustomerConfirm = () => {
		dispatch({
			type: CLOSE_DELETE_CUSTOMER_CONFIRM,
		});
	};

	const deleteCustomer = () => {
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", state.activeCustomerId);

		let url = process.env.REACT_APP_API_URI + "/customers/delete_customer.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);
					closeCustomerConfirm();
					getCustomers();
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	const closeCustomerDoubleCounterDialog = () => {
		dispatch({
			type: CUSTOMER_DOUBLE_COUNTER_DIALOG_CLOSE,
			payload: false,
		});
	};

	const getCounterDoublings = (counterNumber) => {
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("counterNumber", counterNumber);

		let url =
			process.env.REACT_APP_API_URI + "/customers/get_counter_doublings.php";
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					//   console.log(response.data);
					dispatch({
						type: CUSTOMER_COUNTER_DOUBLINGS_LIST,
						payload: response.data.counters,
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	};

	//	remember billing address
	const customerContractPasteBillingAddress = async () => {

		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);

		let url = process.env.REACT_APP_API_URI + "/leads/get_billing_addresses.php";
		let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };

		axios.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {

					//	console.log(response.data);
					dispatch({
						type: CUSTOMER_CONTRACT_PASTE_BILLING_ADDRESS,
						payload: response.data.billingAddresses
					});
				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	}

	const customerContractSaveBillingAddress = () => {

		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append('leadContractCustomerTypesId', state.customerContractCustomerTypesId);
		params.append('leadContractSalutationsId', state.customerContractSalutationsId);
		params.append('leadContractBillingName1', state.customerContractBillingName1);
		params.append('leadContractBillingName2', state.customerContractBillingName2);
		params.append('leadContractBillingName3', state.customerContractBillingName3);
		params.append('leadContractBillingStreet', state.customerContractBillingStreet);
		params.append('leadContractBillingHouseNumber', state.customerContractBillingHouseNumber);
		params.append('leadContractBillingPostalCode', state.customerContractBillingPostalCode);
		params.append('leadContractBillingCity', state.customerContractBillingCity);
		params.append('leadContractBillingDistrict', state.customerContractBillingDistrict);
		params.append('leadContractBillingCountry', state.customerContractBillingCountry);
		params.append('leadContractBillingAdditional', state.customerContractBillingAdditional);
		params.append('leadContractBillingPostboxNo', state.customerContractBillingPostboxNo);
		params.append('leadContractBillingPostboxPostalCode', state.customerContractBillingPostboxPostalCode);
		params.append('leadContractBillingPostboxCity', state.customerContractBillingPostboxCity);

		let url = process.env.REACT_APP_API_URI + "/leads/save_billing_address.php";
		let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };

		axios.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);

					dispatch({
						type: CUSTOMER_CONTRACT_BILLING_ADDRESS_SAVED
					});

				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	}

	const closeCustomerContractBillingAddressPasteDialog = () => {
		dispatch({
			type: CLOSE_CUSTOMER_CONTRACT_BILLING_ADDRESS_PASTE_DIALOG
		});
	}

	const closeCustomerContractBillingAddressSavedDialog = () => {
		dispatch({
			type: CLOSE_CUSTOMER_CONTRACT_BILLING_ADDRESS_SAVED_DIALOG
		});
	}

	const customerContractTakeoverBillingAddress = (id) => {

		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", id);

		let url =
			process.env.REACT_APP_API_URI + "/leads/get_billing_address.php";
		let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };

		axios.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {

					// console.log(response.data);
					dispatch({
						type: CUSTOMER_CONTRACT_TAKEOVER_BILLING_ADDRESS,
						payload: {
							id: response.data.billingAddress.id,
							customerTypesId: response.data.billingAddress.customerTypesId,
							salutationsId: response.data.billingAddress.salutationsId,
							billingName1: response.data.billingAddress.billingName1,
							billingName2: response.data.billingAddress.billingName2,
							billingName3: response.data.billingAddress.billingName3,
							billingStreet: response.data.billingAddress.billingStreet,
							billingHouseNumber: response.data.billingAddress.billingHouseNumber,
							billingPostalCode: response.data.billingAddress.billingPostalCode,
							billingCity: response.data.billingAddress.billingCity,
							billingDistrict: response.data.billingAddress.billingDistrict,
							billingCountry: response.data.billingAddress.billingCountry,
							billingAdditional: response.data.billingAddress.billingAdditional,
							billingPostboxNo: response.data.billingAddress.billingPostboxNo,
							billingPostboxPostalCode: response.data.billingAddress.billingPostboxPostalCode,
							billingPostboxCity: response.data.billingAddress.billingPostboxCity
						},
					});

				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});

	}

	const customerContractDeleteBillingAddress = (id) => {
		const reducedAddresses = state.customerContractBillingAddresses.filter(address => address.id !== id)
		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", id);

		let url = process.env.REACT_APP_API_URI + "/leads/delete_billing_address.php";
		let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };

		axios.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {

					//   console.log(response.data);
					dispatch({
						type: CUSTOMER_CONTRACT_DELETE_BILLING_ADDRESS,
						payload: reducedAddresses,
					});

				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = "#/logout";
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log("Error", error.message);
				}
			});
	}

	//	Customer Copy Dialog
	const closeCustomerCopyDialog = () => {
		dispatch({
			type: CUSTOMER_COPY_DIALOG,
			payload: {
				customerCopyDialogState: false,
				customerCopyDialogId: -1
			}
		});
	}

	//	Customer Active Campaign Change Dialog
	const closeCustomerActiveCampaignChangeDialog = () => {
		dispatch({
			type: CUSTOMER_ACTIVE_CAMPAIGN_CHANGE_DIALOG,
			payload: {
				customerActiveCampaignChangeDialogState: false,
			}
		});
	}

	return (
		<CustomersContext.Provider
			value={{
				//	CUSTOMERS
				activeCustomerId: state.activeCustomerId,
				customerDetailDialogActiveTab: state.customerDetailDialogActiveTab,
				customerDetailDialogError: state.customerDetailDialogError,
				customerDetailDialogState: state.customerDetailDialogState,
				customerDetailDialogTitle: state.customerDetailDialogTitle,
				customers: state.customers,

				closeCustomerDetailDialog,
				deleteCustomerConfirm,
				editCustomer,
				getCustomers,
				saveCustomerDetailDialog,
				setCustomerDetailDialogActiveTab,
				setErrMsg,
				setContractErrMsg,
				setForeigns,

				//	CUSTOMERS ADDRESS
				abc: state.abc,
				abcId: state.abcId,
				activeAddressTab: state.activeAddressTab,
				businessPartnerTypes: state.businessPartnerTypes,
				businessPartnerTypesId: state.businessPartnerTypesId,
				customerCopies: state.customerCopies,
				followUpDate: state.followUpDate,
				followUpDone: state.followUpDone,
				gpNumber: state.gpNumber,
				isPortalAccessAdministration: state.isPortalAccessAdministration,
				supervisors: state.supervisors,
				supervisorsId: state.supervisorsId,
				supervisorsPv: state.supervisorsPv,
				supervisorsPvId: state.supervisorsPvId,

				abcIdChange,
				businessPartnerTypesIdChange,
				followUpDateChange,
				followUpDoneChange,
				goToCopy,
				isPortalAccessAdministrationChange,
				setActiveAddressTab,
				supervisorsIdChange,
				supervisorsPvIdChange,

				//	CUSTOMER OBJECT LIST LOADING DIALOG
				customerObjectListDialogState: state.customerObjectListDialogState,
				customerObjectListIsFinished: state.customerObjectListIsFinished,
				customerObjectListIsEmpty: state.customerObjectListIsEmpty,
				customerObjectListDivision: state.customerObjectListDivision,

				closeCustomerObjectListDialog,
				copyCustomer,
				loadObjectlist,

				//	CUSTOMERS ADDRESS ADDRESS
				addressAdditional: state.addressAdditional,
				city: state.city,
				country: state.country,
				district: state.district,
				houseNumber: state.houseNumber,
				name1: state.name1,
				name2: state.name2,
				name3: state.name3,
				legalForms: state.legalForms,
				legalFormsId: state.legalFormsId,
				postalCode: state.postalCode,
				readingTypes: state.readingTypes,
				readingTypesId: state.readingTypesId,
				salutations: state.salutations,
				salutationsId: state.salutationsId,
				street: state.street,

				addressAdditionalChange,
				cityChange,
				countryChange,
				districtChange,
				houseNumberChange,
				name1Change,
				name2Change,
				name3Change,
				legalFormsIdChange,
				postalCodeChange,
				readingTypesIdChange,
				salutationsIdChange,
				streetChange,

				//	CUSTOMER ADDRESS CONTACT
				fax: state.fax,
				email: state.email,
				phone: state.phone,
				url: state.url,

				emailChange,
				faxChange,
				phoneChange,
				urlChange,

				//	CUSTOMER ADDRESS POSTBOX
				postbox: state.postbox,
				postboxCity: state.postboxCity,
				postboxPostalCode: state.postboxPostalCode,

				postboxChange,
				postboxCityChange,
				postboxPostalCodeChange,

				//	CUSTOMER ADDRESS ADMINISTRATION
				administrations1: state.administrations1,
				administrations1Id: state.administrations1Id,
				administrations2: state.administrations2,
				administrations2Id: state.administrations2Id,
				administrations3: state.administrations3,
				administrations3Id: state.administrations3Id,
				communityCode: state.communityCode,
				ownerId: state.ownerId,
				yesNos: state.yesNos,

				administrations1IdChange,
				administrations2IdChange,
				administrations3IdChange,
				communityCodeChange,
				ownerIdChange,

				//	CUSTOMER CONTACT PERSONS
				activeContactPerson: state.activeContactPerson,
				contactPersonDetailsDialogError: state.contactPersonDetailsDialogError,
				contactPersonDetailsDialogState: state.contactPersonDetailsDialogState,
				contactPersonDetailsDialogTitle: state.contactPersonDetailsDialogTitle,
				contactPersonSalutationsId: state.contactPersonSalutationsId,
				contactPersonTitle: state.contactPersonTitle,
				contactPersonFirstname: state.contactPersonFirstname,
				contactPersonLastname: state.contactPersonLastname,
				contactPersonFunction: state.contactPersonFunction,
				contactPersonDivision: state.contactPersonDivision,
				contactPersonEmail: state.contactPersonEmail,
				contactPersonIsInfoReading: state.contactPersonIsInfoReading,
				contactPersonPhone: state.contactPersonPhone,
				contactPersonFax: state.contactPersonFax,
				contactPersonMobile: state.contactPersonMobile,
				contactPersonIsPortalAccess: state.contactPersonIsPortalAccess,
				contactPersonPortalAccessValidTo:
					state.contactPersonPortalAccessValidTo,
				contactPersons: state.contactPersons,
				deleteModalEntityName: state.deleteModalEntityName,
				deleteModalState: state.deleteModalState,
				deleteModalTitle: state.deleteModalTitle,

				closeContactPersonDialog,
				closeDelete,
				contactPersonDivisionChange,
				contactPersonEmailChange,
				contactPersonFaxChange,
				contactPersonFirstnameChange,
				contactPersonFunctionChange,
				contactPersonIsInfoReadingChange,
				contactPersonIsPortalAccessChange,
				contactPersonLastnameChange,
				contactPersonMobileChange,
				contactPersonPhoneChange,
				contactPersonPortalAccessValidToChange,
				contactPersonSalutationsIdChange,
				contactPersonTitleChange,
				deleteContactPersonConfirm,
				deleteEntityConfirmed,
				editContactPerson,
				getContactPersons,
				saveContactPerson,
				setContactPersonError,

				//	CUSTOMER BANK ACCOUNT
				accountNo: state.accountNo,
				accountOwner: state.accountOwner,
				bankCode: state.bankCode,
				iban: state.iban,

				accountNoChange,
				accountOwnerChange,
				bankCodeChange,
				ibanChange,

				//	CUSTOMER PRODUCTS
				activeProductTab: state.activeProductTab,

				setActiveProductTab,

				//	CUSTOMER PRODUCTS ACTIVE
				activeEnergyCampaignsId: state.activeEnergyCampaignsId,
				activeGasCampaignsId: state.activeGasCampaignsId,
				energyCampaigns: state.energyCampaigns,
				gasCampaigns: state.gasCampaigns,
				isTransition: state.isTransition,
				bIsCustomerEnergyTransitionChange: state.bIsCustomerEnergyTransitionChange,
				isCustomerEnergyTransitionInitialized: state.isCustomerEnergyTransitionInitialized,
				isCustomerEnergyInitialTransition: state.isCustomerEnergyInitialTransition,
				isCustomerEnergyTransitionOpen: state.isCustomerEnergyTransitionOpen,
				isCustomerEnergyTransition: state.isCustomerEnergyTransition,
				customerEnergyTransitionDate: state.customerEnergyTransitionDate,
				bIsCustomerEnergyTransitionTenPercentChange: state.bIsCustomerEnergyTransitionTenPercentChange,
				isCustomerEnergyTransitionTenPercentOpen: state.isCustomerEnergyTransitionTenPercentOpen,
				isCustomerEnergyTransitionTenPercent: state.isCustomerEnergyTransitionTenPercent,
				customerEnergyTransitionTenPercentDate: state.customerEnergyTransitionTenPercentDate,
				bIsCustomerGasTransitionChange: state.bIsCustomerGasTransitionChange,
				isCustomerGasTransitionOpen: state.isCustomerGasTransitionOpen,
				isCustomerGasTransitionInitialized: state.isCustomerGasTransitionInitialized,
				isCustomerGasInitialTransition: state.isCustomerGasInitialTransition,
				isCustomerGasTransition: state.isCustomerGasTransition,
				customerGasTransitionDate: state.customerGasTransitionDate,
				bIsCustomerGasTransitionTenPercentChange: state.bIsCustomerGasTransitionTenPercentChange,
				isCustomerGasTransitionTenPercentOpen: state.isCustomerGasTransitionTenPercentOpen,
				isCustomerGasTransitionTenPercent: state.isCustomerGasTransitionTenPercent,
				customerGasTransitionTenPercentDate: state.customerGasTransitionTenPercentDate,
				offeredEnergyCampaignsId: state.offeredEnergyCampaignsId,
				offeredGasCampaignsId: state.offeredGasCampaignsId,

				activeEnergyCampaignsIdChange,
				activeGasCampaignsIdChange,
				isCustomerEnergyTransitionChange,
				customerEnergyTransitionDateChange,
				isCustomerEnergyTransitionTenPercentChange,
				customerEnergyTransitionTenPercentDateChange,
				isCustomerGasTransitionChange,
				customerGasTransitionDateChange,
				isCustomerGasTransitionTenPercentChange,
				customerGasTransitionTenPercentDateChange,
				offeredEnergyCampaignsIdChange,
				offeredGasCampaignsIdChange,

				//	CUSTOMER PRODUCTS HISTORY
				productHistories: state.productHistories,

				getProductHistories,

				//	CUSTOMER CONTRACTS
				activeContractTab: state.activeContractTab,
				activeCustomerContract: state.activeCustomerContract,
				contractDialogTitle: state.contractDialogTitle,
				contracts: state.contracts,
				customerContractsDialogError: state.customerContractsDialogError,
				customerContractsDialogState: state.customerContractsDialogState,
				customerContractLastImportDate: state.customerContractLastImportDate,
				deleteContractName: state.deleteContractName,
				deleteContractState: state.deleteContractState,
				isContractEdit: state.isContractEdit,

				closeContractsDialog,
				closeDeleteContract,
				deleteContract,
				deleteCustomerContract,
				editCustomerContract,
				saveContractDetails,
				setActiveContractTab,

				//CUSTOMER CONTRACT CONTRACT
				customerContractBillTurnStart: state.customerContractBillTurnStart,
				customerContractBillTurnEnd: state.customerContractBillTurnEnd,
				customerContractGrossBillAmount: state.customerContractGrossBillAmount,
				customerContractIsBio10: state.customerContractIsBio10,
				customerContractBio10StartDate: state.customerContractBio10StartDate,
				customerContractIsBio5: state.customerContractIsBio5,
				customerContractBio5StartDate: state.customerContractBio5StartDate,
				customerContractIsClimatePlus: state.customerContractIsClimatePlus,
				customerContractDivisionsId: state.customerContractDivisionsId,
				customerContractPeriodConsumption:
					state.customerContractPeriodConsumption,
				customerContractPotsId: state.customerContractPotsId,
				customerContractProducts: state.customerContractProducts,
				customerContractProductsId: state.customerContractProductsId,
				customerContractProfileType: state.customerContractProfileType,
				customerContractValidFrom: state.customerContractValidFrom,
				customerContractValidTo: state.customerContractValidTo,
				isCustomerContractTransitionOpen: state.isCustomerContractTransitionOpen,
				isCustomerContractTransitionInitialized: state.isCustomerContractTransitionInitialized,
				isCustomerContractInitialTransition: state.isCustomerContractInitialTransition,
				isCustomerContractTransition: state.isCustomerContractTransition,
				customerContractTransitionDate: state.customerContractTransitionDate,
				isCustomerContractTransitionTenPercentOpen: state.isCustomerContractTransitionTenPercentOpen,
				isCustomerContractTransitionTenPercent: state.isCustomerContractTransitionTenPercent,
				customerContractTransitionTenPercentDate: state.customerContractTransitionTenPercentDate,
				divisions: state.divisions,
				isOptionen: state.isOptionen,
				isPots: state.isPots,
				isValidTo: state.isValidTo,
				pots: state.pots,

				customerContractDivisionsIdChange,
				customerContractGrossBillAmountChange,
				customerContractIsBio10Change,
				customerContractBio10StartDateChange,
				customerContractIsBio5Change,
				customerContractBio5StartDateChange,
				customerContractIsClimatePlusChange,
				customerContractPeriodConsumptionChange,
				customerContractPotsIdChange,
				customerContractProductsIdChange,
				customerContractProfileTypeChange,
				customerContractValidFromChange,
				customerContractValidToChange,
				isCustomerContractTransitionChange,
				customerContractTransitionDateChange,
				isCustomerContractTransitionTenPercentChange,
				customerContractTransitionTenPercentDateChange,

				//	CUSTOMER CONTRACT COUNTER
				activeContractCounterTab: state.activeContractCounterTab,
				customerCounterBhkw: state.customerCounterBhkw,
				customerCounterContractAccount: state.customerCounterContractAccount,
				customerCounterContractAccountDescription:
					state.customerCounterContractAccountDescription,
				customerCounterIntendedUse: state.customerCounterIntendedUse,
				customerCounterLoadProfileIms: state.customerCounterLoadProfileIms,
				customerCounterMediumVoltage: state.customerCounterMediumVoltage,
				customerCounterNumberCurrent: state.customerCounterNumberCurrent,
				customerCounterNumberDoubling: state.customerCounterNumberDoubling,
				customerCounterNumberFormer: state.customerCounterNumberFormer,

				customerCounterBhkwChange,
				customerCounterContractAccountChange,
				customerCounterContractAccountDescriptionChange,
				customerCounterIntendedUseChange,
				customerCounterMediumVoltageChange,
				customerCounterNumberCurrentChange,
				customerCounterNumberFormerChange,
				getCounterDoublings,
				setActiveContractCounterTab,

				//	CUSTOMER CONTRACT COUNTER CHANGE
				changeReasons: state.changeReasons,
				customerCounterChangeReasonsId: state.customerCounterChangeReasonsId,
				customerCounterChangeSupplyStart:
					state.customerCounterChangeSupplyStart,
				customerCounterFormerSupplierId: state.customerCounterFormerSupplierId,
				customerCounterInstallmentCycle: state.customerCounterInstallmentCycle,
				customerCounterIsNetworkConfirmed:
					state.customerCounterIsNetworkConfirmed,
				customerCounterIsNetworkRequest: state.customerCounterIsNetworkRequest,
				customerCounterLoadProfilesId: state.customerCounterLoadProfilesId,
				customerCounterNetworksId: state.customerCounterNetworksId,
				loadProfiles: state.loadProfiles,
				networks: state.networks,
				suppliers: state.suppliers,

				customerCounterChangeReasonsIdChange,
				customerCounterChangeSupplyStartChange,
				customerCounterFormerSupplierIdChange,
				customerCounterInstallmentCycleChange,
				customerCounterLoadProfilesIdChange,
				customerCounterNetworksIdChange,

				//	CUSTOMER CONTRACT COUNTER LOCATION
				customerCounterLocationAdditional:
					state.customerCounterLocationAdditional,
				customerCounterLocationCity: state.customerCounterLocationCity,
				customerCounterLocationCountry: state.customerCounterLocationCountry,
				customerCounterLocationDistrict: state.customerCounterLocationDistrict,
				customerCounterLocationHouseNumber:
					state.customerCounterLocationHouseNumber,
				customerCounterLocationMeasureLocation:
					state.customerCounterLocationMeasureLocation,
				customerCounterLocationMarketLocation:
					state.customerCounterLocationMarketLocation,
				customerCounterLocationPostalCode:
					state.customerCounterLocationPostalCode,
				customerCounterLocationStreet: state.customerCounterLocationStreet,

				customerCounterLocationAdditionalChange,
				customerCounterLocationCityChange,
				customerCounterLocationCountryChange,
				customerCounterLocationDistrictChange,
				customerCounterLocationHouseNumberChange,
				customerCounterLocationMarketLocationChange,
				customerCounterLocationMeasureLocationChange,
				customerCounterLocationPostalCodeChange,
				customerCounterLocationStreetChange,

				//	CUSTOMER CONTRACT COUNTER BANK
				customerCounterAccountNo: state.customerCounterAccountNo,
				customerCounterAccountOwner: state.customerCounterAccountOwner,
				customerCounterBankCode: state.customerCounterBankCode,
				customerCounterIban: state.customerCounterIban,

				customerCounterAccountNoChange,
				customerCounterAccountOwnerChange,
				customerCounterBankCodeChange,
				customerCounterIbanChange,

				//	CUSTOMER CONTRACT COUNTER END
				customerCounterContractEnd: state.customerCounterContractEnd,
				customerCounterContractTerminationReasonsId:
					state.customerCounterContractTerminationReasonsId,
				terminationReasons: state.terminationReasons,
				customerCounterValue: state.customerCounterValue,
				customerCounterValueNt: state.customerCounterValueNt,
				isCustomerCounterLeaveDone: state.isCustomerCounterLeaveDone,

				customerCounterContractEndChange,
				customerCounterContractTerminationReasonsIdChange,
				customerCounterValueChange,
				customerCounterValueNtChange,
				isCustomerCounterLeaveDoneChange,

				//	CUSTOMER CONTRACT BILLING
				customerContractBillingAdditional:
					state.customerContractBillingAdditional,
				customerContractBillingCity: state.customerContractBillingCity,
				customerContractBillingCountry: state.customerContractBillingCountry,
				customerContractCustomerTypesId: state.customerContractCustomerTypesId,
				customerContractBillingDistrict: state.customerContractBillingDistrict,
				customerContractBillingHouseNumber:
					state.customerContractBillingHouseNumber,
				customerContractBillingMail1: state.customerContractBillingMail1,
				customerContractBillingMail2: state.customerContractBillingMail2,
				customerContractBillingMail3: state.customerContractBillingMail3,
				customerContractBillingName1: state.customerContractBillingName1,
				customerContractBillingName2: state.customerContractBillingName2,
				customerContractBillingName3: state.customerContractBillingName3,
				customerContractBillingPostalCode:
					state.customerContractBillingPostalCode,
				customerContractBillingPostboxCity:
					state.customerContractBillingPostboxCity,
				customerContractBillingPostboxNo:
					state.customerContractBillingPostboxNo,
				customerContractBillingPostboxPostalCode:
					state.customerContractBillingPostboxPostalCode,
				customerContractBillingStreet: state.customerContractBillingStreet,
				customerContractSalutationsId: state.customerContractSalutationsId,
				customerTypes: state.customerTypes,

				customerContractBillingAdditionalChange,
				customerContractBillingCityChange,
				customerContractBillingCountryChange,
				customerContractBillingDistrictChange,
				customerContractBillingHouseNumberChange,
				customerContractBillingName1Change,
				customerContractBillingName2Change,
				customerContractBillingName3Change,
				customerContractBillingPostalCodeChange,
				customerContractBillingPostboxCityChange,
				customerContractBillingPostboxNoChange,
				customerContractBillingPostboxPostalCodeChange,
				customerContractBillingStreetChange,
				customerContractSalutationsIdChange,
				customerContractCustomerTypesIdChange,

				//	CUSTOMER CONTACT HISTORY
				activeContactHistory: state.activeContactHistory,
				contactHistories: state.contactHistories,
				contactHistoryDetailDocument: state.contactHistoryDetailDocument,
				contactHistoryDetailObjectId: state.contactHistoryDetailObjectId,
				contactHistoryDetailRemarks: state.contactHistoryDetailRemarks,
				contactHistoryDialogState: state.contactHistoryDialogState,
				contactHistoryDialogTitle: state.contactHistoryDialogTitle,
				contactHistoryDetailTopic: state.contactHistoryDetailTopic,
				contactHistoryError: state.contactHistoryError,
				contactHistoryIsUser: state.contactHistoryIsUser,
				customerObjects: state.customerObjects,
				deleteCustomerContactHistoryConfirmName:
					state.deleteCustomerContactHistoryConfirmName,
				deleteCustomerContactHistoryConfirmState:
					state.deleteCustomerContactHistoryConfirmState,

				changeContactHistoryDetailObjectId,
				changeContactHistoryDetailRemarks,
				changeContactHistoryDetailTopic,
				closeContactHistoryDialog,
				closeCustomerContactHistoryConfirm,
				confirmDeleteContactHistory,
				contactHistoryDetailDocumentChange,
				contactHistoryDetailDocumentDelete,
				deleteCustomerContactHistory,
				editContactHistory,
				getContactHistories,
				saveContactHistoryDialog,
				setContactHistoryError,

				//	DELETE CUSTOMER
				deleteCustomerConfirmName: state.deleteCustomerConfirmName,
				deleteCustomerConfirmState: state.deleteCustomerConfirmState,

				closeCustomerConfirm,
				deleteCustomer,

				//	COUNTER DOUBLINGS
				customerCounterDoublingsList: state.customerCounterDoublingsList,
				customerDoubleCounterDialogState:
					state.customerDoubleCounterDialogState,
				closeCustomerDoubleCounterDialog,

				//	remember billing address
				customerContractBillingAddressSavedDialogState: state.customerContractBillingAddressSavedDialogState,
				customerContractBillingAddressPasteDialogState: state.customerContractBillingAddressPasteDialogState,
				customerContractBillingAddresses: state.customerContractBillingAddresses,

				customerContractPasteBillingAddress,
				customerContractSaveBillingAddress,
				closeCustomerContractBillingAddressSavedDialog,
				closeCustomerContractBillingAddressPasteDialog,
				customerContractTakeoverBillingAddress,
				customerContractDeleteBillingAddress,

				//	Customer Copy Dialog
				customerCopyDialogId: state.customerCopyDialogId,
				customerCopyDialogState: state.customerCopyDialogState,

				closeCustomerCopyDialog,

				//	Customer Active Campaign Change Dialog
				customerActiveCampaignChangeContracts: state.customerActiveCampaignChangeContracts,
				customerActiveCampaignChangeDialogState: state.customerActiveCampaignChangeDialogState,
				customerActiveCampaignChangeFileName: state.customerActiveCampaignChangeFileName,
				customerActiveCampaignChangeLoading: state.customerActiveCampaignChangeLoading,

				closeCustomerActiveCampaignChangeDialog
			}}
		>
			{props.children}
		</CustomersContext.Provider>
	);
};

export default CustomersState;
